import React,{Component} from 'react';

import ReactDOM from 'react-dom';

import {Link} from 'react-router-dom';

class Logout extends React.Component{

    render(){

        return(
            <div>

          yoyu have been logged out
               
            </div>
        )

    }
}

export default Logout;
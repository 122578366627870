import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { alpha } from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Pagination from "@material-ui/lab/Pagination";
import Vista from "./vista";
import MediaCard from "./listado_pedidos";

import React, { Component } from "react";
import {authenticationService} from '../../../../checkLoginStatus';
import Formulario from "./formulario";
import moment from "moment-timezone";

class Pedidos extends Component {
  constructor(props) {
    super(props);

    this.state = {

      token: "",
      processedServices:[],
      listadoPedidos: [],
      listadoUsuarios: [],
      mensaje:"",
      statusMensaje:false,
      statusLoad:false,
      token:"",
      serverUrl:"",
      statusData:"",
      server_dt:"",
      open: false,
      open2: false,
      file: "",
      totalPages: 0,
      totalPagesUsuarios:0,
      selectedUsername:"",
      flag_pedidos_pendientes:false,
      date1:"",
      date2:"",
      date3:"",
      nombreVendedor:"",
      global_id:0,
      gobal_imageUrl:"",
      efectividad_visita:0,
      fecha_string:"",
      fecha_string_hasta:"",
      calificado:"",
      arrayCodificacion:[],
      promedioAsesor:0,
      download_report_flag:false,
      fecha_desde:"",
      diasinforme:""

   
    };


    this.gettingPedidos     = this.gettingPedidos.bind(this);
    this.getclickedPagination = this.getclickedPagination.bind(this);
    this.deletePedidos      = this.deletePedidos.bind(this);
    this.getUsuarios        = this.getUsuarios.bind(this);
    this.selectvendedor     = this.selectvendedor.bind(this);
    this.onChangeP1         = this.onChangeP1.bind(this);
    this.pedidosPendientes  = this.pedidosPendientes.bind(this);
    this.cargar_calificacion  = this.cargar_calificacion.bind(this);
    this.downloadAction     = this.downloadAction.bind(this);
    this.handleDateChange   = this.handleDateChange.bind(this);
    this.handleDateChange2  = this.handleDateChange2.bind(this);
    this.handleDateChangeHasta = this.handleDateChangeHasta.bind(this);

    this.handleDateYearMonth = this.handleDateYearMonth.bind(this);
    this.diasInforme = this.diasInforme.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.handleCloseYes_cargaManual = this.handleCloseYes_cargaManual.bind(this);

    this.handleClose_delete = this.handleClose_delete.bind(this);
    this.onchangeNumeric = this.onchangeNumeric.bind(this); 
    this.download_informe2 = this.download_informe2.bind(this);
    this.download_informe3 = this.download_informe3.bind(this);



 
  }


  download_informe_per_date(informeType){
      this.setState({statusLoad:true});
      var dates = [];
      if(informeType=="1"){

        this.setState({statusLoad:true});
        var start = new Date(this.state.fecha_string);
        var end = new Date(this.state.fecha_string_hasta);
        
  
        var loop = new Date(start);
        while(loop <= end){
  
          let date1 = moment.tz(loop, "YYYY-MM-DD", "America/Bogota").local();
          var check = moment(date1, 'YYYY/MM/DD');
          var month = check.format('MM');
          var day   = check.format('DD');
          var year  = check.format('YYYY');
  
          let fulldate = year+"-"+month+"-"+day;
          //console.log(fulldate.toString())
          dates.push(fulldate.toString());
  
          var newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }

      }
      if(informeType=="2"){
        const resultArray = this.state.diasinforme.split(',').map(number => {
          const parsedNumber = parseInt(number, 10);
          return (parsedNumber >= 1 && parsedNumber <= 9) ? '0' + parsedNumber : number;
        }); 
  
        dates = resultArray.map(each => this.state.date3 + each);
      }

      let data = {dates: dates, selecteduser:this.state.selectedUsername};
   

      fetch(this.state.serverUrl + "/api/downloadnuevo/?fecha="+this.state.fecha_string+"&username="+this.state.selectedUsername, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
            //Authorization: "Bearer " + this.state.token,
            
          },
          body: JSON.stringify(data) 
        }) .then(
          response => {
            response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
           
            a.href = url;
            //a.download = "INFORME--"+this.state.nombreVendedor+"--"+this.state.selectedUsername+".xlsx";
            a.download = "INFORME--TODOS LOS ASESORES--"+this.state.fecha_string+".xlsx";
            a.click();
          });
          this.setState({statusLoad:false});
        });

  }

  download_informe2(){  

      if(this.state.fecha_string!="" && this.state.fecha_string_hasta!=""){
        this.download_informe_per_date("1");
      }else{
        alert("Por favor ingrese un rango de fechas primero")
      }
      
  }

  download_informe3(){

    const regex = /^(0?[1-9]|[12][0-9]|3[01])(,(0?[1-9]|[12][0-9]|3[01]))*$/;
    let data = regex.test(this.state.diasinforme);

    if(this.state.date3!=""){

      if(data){
        this.download_informe_per_date("2");
      }else{
        alert("Por favor valide, los dias ingresados entre 1 y 31 solo deben de ser separados por comas , ningun otro caracter es permitido");
      } 

    }else{

        alert("debe de seleccionar Anio y mes primero")

    }
    
      
      
  }

  onchangeNumeric(e){
    
    const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
         this.setState({efectividad_visita: e.target.value})
      }

  }

  diasInforme(e){

    const inputValue = e.target.value;
    this.setState({ diasinforme: inputValue });

  }

  handleDateYearMonth(date){

    // Format the selected date using moment-timezone
    let formattedDate = moment.tz(date, 'America/Bogota').format('YYYY-MM');

    // Extract month and year
    let month = moment(formattedDate).format('MM');
    let year = moment(formattedDate).format('YYYY');

    // Do something with the formatted date, month, and year
    // console.log('Formatted Date:', formattedDate);
    // console.log('Month:', month);
    // console.log('Year:', year);
    this.setState({date3:year+"-"+month+"-"})

  }

  handleDateChange(value){

    

    let date1 = moment.tz(value, "YYYY-MM-DD", "America/Bogota").local();
    var check = moment(date1, 'YYYY/MM/DD');
    var month = check.format('MM');
    var day   = check.format('DD');
    var year  = check.format('YYYY');

    let fulldate = year+"-"+month+"-"+day;

    const dateNew = moment.tz(value, "America/Bogota").format();

    this.setState({date1:dateNew.toString(),fecha_string:fulldate.toString()});


    this.getUsuarios(1,fulldate);
    

  }

  handleDateChangeHasta(value){

    let date2 = moment.tz(value, "YYYY-MM-DD", "America/Bogota").local();
    var check = moment(date2, 'YYYY/MM/DD');
    var month = check.format('MM');
    var day   = check.format('DD');
    var year  = check.format('YYYY');

    let fulldate = year+"-"+month+"-"+day;

    const dateNew = moment.tz(value, "America/Bogota").format();

    this.setState({date2:dateNew.toString(),fecha_string_hasta:fulldate.toString()});


  }

  handleDateChange2(value){

    this.setState({date2:value});

  }

  downloadAction(id,imageUrl){
    
   this.setState({"open":true,"global_id":id,"gobal_imageUrl":imageUrl});

  }

  cargar_calificacion(){

  
    if(this.state.selectedUsername!=0){

            if(this.state.calificado=="NO"){

              let data = {fk_id_account: this.state.selectedUsername,fecha:this.state.fecha_string,efectividad_visita:this.state.efectividad_visita};
          
              fetch(this.state.serverUrl + "/api/createcalificacion/", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json"
                  //Authorization: "Bearer " + this.state.token,
                  
                },
                body: JSON.stringify(data) 
          
              }) .then(response => {
                if (response.status === 201) {
          
                    response.json().then(data => {
                        
                        //console.log("upload...");
                        this.setState({calificado:"",selectedUsername:0,efectividad_visita:0,nombreVendedor:"",listadoPedidos:[],totalPages:0});
                        this.getUsuarios(1,this.state.fecha_string);
                    
                    });
                }
                
            });

          }else{

              alert("EL ASESOR YA FUE CALIFICADO PREVIAMENTE");

          }

    }else{

      alert("SELECCIONE UN ASESOR PRIMERO");
    }

  }

  pedidosPendientes(value){

    fetch(this.state.serverUrl + "/api/getpedidos/?page="+value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
      },
    }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
           
            this.setState({listadoPedidos:data.data,totalPages:data.total_pages,flag_pedidos_pendientes:false});

          });
      }
      else if (response.status === 401) {
        authenticationService.loGout();
      }
  });

  }

  getUsuarios(pagination,fecha){

        

         fetch(this.state.serverUrl + "/api/usersvisitas/?fecha="+fecha+"&page="+pagination, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
              //Authorization: "Bearer " + this.state.token,
            },
          }) .then(response => {
            if (response.status === 200) {

                response.json().then(data => {
                  
                  
                  this.setState({
                    
                    listadoUsuarios:data.data,
                    totalPagesUsuarios: data.total_pages
                  });

                });
            }
            else if (response.status === 401) {
              authenticationService.loGout();
            }
        });

  }

  onChangeP1(event, value){


    
    this.getUsuarios(value,this.state.fecha_string);
    //this.setState({listadoPedidos: []});
  }


  handleClose_delete(){


    this.setState({statusLoad:true});
    fetch(this.state.serverUrl + "/api/getpedidos/?id="+this.state.global_id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //Authorization: "Bearer " + this.state.token,
        
      },
     

      }) .then(response => {
         
          if (response.status === 200) {
              
              response.json().then(data => {
                  this.getclickedPagination(0,1);
                  //this.gettingPedidos(1);
                  this.setState({statusLoad:false,open2:false,global_id:0});
                  this.getUsuarios(1);
            
              });
          }
        
      });

  }


  deletePedidos(id, s){

       this.setState({global_id:id, open2:true});
    
  }



  getclickedPagination(event, value){
    

    if(this.state.flag_pedidos_pendientes){

      
      this.gettingPedidos(value);

    }else{

 
      this.pedidosPendientes(value);
    }

    
  }

  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.state.token =tokenStorage;
    
    this.setState({

      serverUrl: sessionStorage.getItem("serverUrl"),
    });
  
  
    //this.getUsuarios(1);
  }

  gettingPedidos(value){

    this.setState({listadoPedidos:[],download_report_flag:false,totalPages:0,promedioAsesor:0});
  
    let data = {};
    
   if(this.state.date1!=""){

    //  if(this.state.date1<=this.state.date2){

        var date1 = new Date(this.state.date1)
        var desde = date1.getFullYear()+ '-' +  (date1.getMonth() + 1)  + '-' +date1.getDate();  

        var date2 = new Date(this.state.date2)
        //var hasta = date2.getFullYear()+ '-' +  (date2.getMonth() + 1)  + '-' +date2.getDate();  
        var hasta = desde;
    
        data = {username: this.state.selectedUsername,page:value,desde_1:this.state.fecha_string,hasta_1:this.state.fecha_string};
        this.state.fecha_desde=desde;
    /*    
      }else{

      
        this.setState({elmensaje:"LAS FECHA DESDE NO PUEDE SER MAYOR A LA FECHA HASTA ",statusLoad:false});
      
      }*/

    }else{

      alert("DEBE DE SELECCIONAR UN FECHA PRIMERO");
    }


 

   fetch(this.state.serverUrl + "/api/getpedidosvisitas/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
        
      },
      body: JSON.stringify(data) 

  }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {

            if(data.data.length>0){

              let total_calificacion = 0;
              let promedio = 0;
              let prospectos=0;
              data.data.map((item) => {

                if(item.es_prospecto==true){
                  prospectos=prospectos+1;
                }
               
                total_calificacion=total_calificacion+item.sumatoria;  

              })
              if(prospectos>0){

                promedio = total_calificacion/(data.data.length-prospectos);

              }else{
                promedio = total_calificacion/data.data.length;
              }
              
              
              
           
            data.data.map(item=>{
              //const number = moment(item.date_full, ["h:mm A"]).format("HH:mm:ss");
              //console.log("date_full --"+number);
              //const number2 = moment.utc(item.date_of_load_meeting, "YYYY-MM-DD HH:mm:ss.SSS");

              if(item.date_of_load_meeting!=null){


                const time = item.date_of_load_meeting;
               
                const date = new Date(time);
                const format1 = "YYYY-MM-DD HH:mm:ss"
                const dateTime1 = moment(date).format(format1);
                
                //console.log("date_of_load_meeting --"+dateTime1);
  
                var date_s = moment(dateTime1).format('YYYY-MM-DD');
                var hour_s = moment(dateTime1).format('hh:mm:ss a');

                
                item.date_of_load_meeting = date_s+" "+hour_s;

                const time2 = item.fecha_de_visita_con_hora;
                const date2 = new Date(time2);
                const dateTime2 = moment(date2).format(format1);
  
                var date_s = moment(dateTime2).format('YYYY-MM-DD');
                var hour_s = moment(dateTime2).format('hh:mm:ss a');
  
                //console.log("militar time "+date_s+" "+hour_s);
                item.fecha_de_visita_con_hora = date_s+" "+hour_s;

              }
             

             
            })   
            // console.log("HERERE.****");
            // console.log(data.data);
              this.setState({listadoPedidos:data.data,totalPages:data.total_pages,promedioAsesor:promedio,download_report_flag:true});

            }
            
         
          });
      }
     
  });

  }



  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });

    let that = this;

    if(event.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)){
      this.setState({statusLoad:true});
      let extensionFILe = event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf('.') + 1).toLowerCase();
     
      let data = new FormData();
      data.append("upload", event.target.files[0]);
      data.append("folder_name", sessionStorage.getItem("username"));
      data.append("extention", extensionFILe);

      fetch(this.state.serverUrl+"/api/pedidos/", {
        method: "POST",
        //headers: {
        //  Authorization: "Bearer " + this.state.token,
        //},
        body: data,
      })
        .then((response) => {

           
           if(response.status==400){

            alert("LA IMAGEN YA FUE CARGADA PREVIAMENTE");

           }
           if(response.status==201){

              //console.log("imagen cargada");
              that.gettingPedidos(1);  

           }
          //this.setState({ imagename: cedula + ".jpg" });
          this.setState({statusLoad:false});
        })
        .catch((err) => {
          this.setState({ error: err });
        });

    }else{

      alert("Seleccione una imagen para continuar");

    }
    

  };

  selectvendedor(event, value,nombreven,calificado){

      
    this.setState({listadoPedidos:[],nombreVendedor:nombreven,selectedUsername:value,flag_pedidos_pendientes:true,calificado:calificado});


    setTimeout(() => {
      this.gettingPedidos(1);
   }, 500);
  }


  handleCloseYes_cargaManual(){


    
  window.open(this.state.gobal_imageUrl);

  this.setState({statusLoad:true});
  fetch(this.state.serverUrl + "/api/getpedidosvisitas/?id="+this.state.global_id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: "Bearer " + this.state.token,
      
    },
   

    }) .then(response => {
      
        if (response.status === 200) {
            
            response.json().then(data => {
                this.pedidosPendientes(1);
                this.setState({statusLoad:false,open:false,global_id:0,gobal_imageUrl:""});
                this.getUsuarios(1);
                
          
            });
        }
      
    });

  }

  handleClose(){


    this.setState({open:false,open2:false});
  }



  render() {
    return (
      <div>
    

        <Vista
          allProductos={this.state.listadoUsuarios}
          onFileChange={this.onFileChange}
          clickingRrow={this.selectvendedor}
          
         
          onChangeP1={this.onChangeP1}
          date1={this.state.date1}
          date2={this.state.date2}
          date3={this.state.date3}
          diasinforme={this.state.diasinforme}
          cargar={this.cargar_calificacion}

          handleDateChange={this.handleDateChange}
          handleDateChange2={this.handleDateChange2}
          handleDateChangeHasta={this.handleDateChangeHasta}
          handleDateYearMonth={this.handleDateYearMonth} 
          diasInforme={this.diasInforme}

          selectedUser={this.state.nombreVendedor}
          efectividad_visita={this.state.efectividad_visita}
          onchangeNumeric={this.onchangeNumeric}
          promedioAsesor={this.state.promedioAsesor}
          download_informe2={this.download_informe2}
          download_informe3={this.download_informe3}
          download_report_flag={this.state.download_report_flag}

          totalPagesUsuarios={this.state.totalPagesUsuarios}

      
        ></Vista>

        <Grid
container
spacing={0}
direction="column"
alignItems="center"
justify="center"
><br></br>
<center>
<Pagination
count={parseInt(this.state.totalPagesUsuarios)}
color="primary"
onChange={this.onChangeP1}
/>
<br/>


</center>
</Grid>

        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> 

        <center>
          <h2>{this.state.mensaje}</h2>

          <p>{this.state.statusData}</p>
        </center>


        {this.state.listadoPedidos.map((item) => {
     

          return (
            <div key={item.id}>
              <MediaCard
              
                
                urlImage={item.image_name}
                titulo={item.fecha_de_visita_con_hora}
                status={item.descargado}
               // totaltratamientos={item.vecesVisto}
               // editar={this.handleClicks.bind(this, item.id)}
               cedula={item.username}
               nombre={item.vendedor}
               eliminar={this.deletePedidos.bind(this, item.id)}
               descargar={this.downloadAction.bind(this, item.id, item.image_name)}
               latitude={item.latitude}
               longitude={item.longitude}

               nitcliente={item.tiendaCliente_nit}
               nombrecliente={item.tiendaCliente_nombre}


               handleCloseYes_cargaManual={this.handleCloseYes_cargaManual}
               handleClose_delete={this.handleClose_delete}
               handleClose={this.handleClose}
               open={this.state.open}
               open2={this.state.open2}
               cantidad_referencias={item.cantidad_referencias}
               cantidad_unidades={item.cantidad_unidades}
              
               fitomillonario={item.fitomillonario}
               arrayCodificacion={item.itemsCodificacion}
               arrayCapacitacion={item.itemsCapacitacion}
               cartera={item.cartera}

               visita_presencial={item.visita_presencial}
               visita_con_pedido = {item.visita_con_pedido}

               califi_visita_presencial = {item.califi_visita_presencial}
               califi_visita_con_pedido = {item.califi_visita_con_pedido}
               califi_cantidad_referencias={item.califi_cantidad_referencias}
               califi_fitomillonario = {item.califi_fitomillonario}
               califi_cartera = {item.califi_cartera}
               califi_codificacion_productos = {item.califi_codificacion_productos}
               califi_capacitacion_productos = {item.califi_capacitacion_productos}
               sumatoria={item.sumatoria}
               pedido_number={item.pedido_number}
               datefull = {item.date_full}
               date_of_load_meeting = {item.date_of_load_meeting}
               revisado = {item.revisado}
               esprospecto = {item.es_prospecto? "SI":"NO"}
               item_prospecto = {item.es_prospecto}
               array_prospectos = {item.prospectos} 
               

              ></MediaCard>
              <br /> <br />
              <Divider />
              <br /> <br />
            </div>
          );
        })}

       
        {this.state.totalPages>0? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <center>
            <Pagination
              count={parseInt(this.state.totalPages)}
              color="primary"
              onChange={this.getclickedPagination}
            />
          </center>

        </Grid>
        ):("")}

      </div>
    );
  }
}

export default Pedidos;

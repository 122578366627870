
import React, { Component } from "react";
import Leaflet from "leaflet";
import { MapContainer, CircleMarker, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
// import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = Leaflet.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: iconShadow
});

Leaflet.Marker.prototype.options.icon = DefaultIcon;

class Mapa extends Component {
  constructor(props) {
    super();
    this.state = {
      lat: props.lat,
      lng: props.lng,
      zoom: 5,
      markerPoint: {
        x: 320,
        y: 192
      }
    };
    this.refMap = React.createRef();
    this.circleRef = React.createRef();
  }

  render() {
    const { lat, lng, zoom } = this.state;
    const position = [lat, lng];
    return (
      <MapContainer
        ref={this.refMap}
        center={position}
        zoom={zoom}
        style={{ height: "400px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker
          ref={this.bindMarker}
          position={position}
          draggable="True"
          pane="popupPane"
        />
        <CircleMarker
          ref={this.bindCircle}
          center={position}
          color="green"
          fillColor="red"
          radius={20}
          fillOpacity={0.5}
          stroke={false}
        >
          <Popup>
            <span>
              A pretty CSS3 popup. <br /> Easily customizable.
            </span>
          </Popup>
        </CircleMarker>
      </MapContainer>
    );
  }
}

export default Mapa;

import CircularProgress from "@material-ui/core/CircularProgress";

import Divider from "@material-ui/core/Divider";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";

import React, { Component } from "react";
import {authenticationService} from '../../../checkLoginStatus';
import Vista from "./vista";
import MediaCard from "./listado_pedidos";


import Button from '@material-ui/core/Button';



class Restart extends Component {
  constructor(props) {
    super(props);

    this.state = {

      token: "",
      processedServices:[],
      listadoPedidos: [],
      mensaje:"",
      statusMensaje:false,
      statusLoad:false,
      token:"",
      serverUrl:"",
      statusData:"",
      server_dt:"",
      open: false,
      file: "",
      totalPages: 0,
      nitCliente:"",
      clientesTiendas:[],
      selectedIdCostumer:"",
      latitude: '',
      longitude: '',
      clienteSeleccionado:'',
      nitSeleccionado:'',
      motivoPqr:'',
      facturaPqr:'',
      lotePqr:'',
      cantidadPqr:'',
      zoom: 5,
      markerPoint: {
        x: 320,
        y: 192
      },
      lat: 39.9653301,
      lng: 32.7760817,
      

   
    };


    this.gettingPedidos     = this.gettingPedidos.bind(this);
    this.getclickedPagination = this.getclickedPagination.bind(this);
    this.deletePedidos      = this.deletePedidos.bind(this);
    this.onChangeCliente    = this.onChangeCliente.bind(this);
    this.onChangePqr        = this.onChangePqr.bind(this);

    this.gettingClientes    = this.gettingClientes.bind(this);
    this.selectedCLiente    = this.selectedCLiente.bind(this);
    this.uploadPedido       = this.uploadPedido.bind(this);
    this.bridgeGettingCLientes = this.bridgeGettingCLientes.bind(this);

    this.onChangeP1         = this.onChangeP1.bind(this);

 
  }


  onChangeP1(r,value){


      this.bridgeGettingCLientes(value);
  }

  deletePedidos(id, s){

        this.setState({statusLoad:true});
        fetch(this.state.serverUrl + "/api/getpedidos/?id="+id, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + this.state.token,
            
          },
         

          }) .then(response => {
             
              if (response.status === 200) {
                  
                  response.json().then(data => {
                      this.gettingPedidos(1);
                      this.setState({statusLoad:false});
                
                  });
              }
            
          });
    
  }


  getclickedPagination(event, value){

    this.gettingPedidos(value);
  }

  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.state.token =tokenStorage;
    
    this.setState({

      serverUrl: sessionStorage.getItem("serverUrl"),
    });
  
    this.gettingPedidos(1);  


    const location = window.navigator && window.navigator.geolocation
    
    if (location) {
      location.getCurrentPosition((position) => {
        
     
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          lat:position.coords.latitude,
          lng: position.coords.longitude
          
        })
      }, (error) => {
        //this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
      })
    }


    
  }




  gettingClientes(){

     
      this.bridgeGettingCLientes(1);

  }

  bridgeGettingCLientes(value){

   
    this.setState({mensaje:""});
    fetch(this.state.serverUrl + "/api/tiendaclientes/?nit="+this.state.nitCliente+"&page="+value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
      },
    }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
            
            
            this.setState({
              clientesTiendas:data.data,
              totalPagesUsuarios: data.total_pages
            });

          });
      }
      else if (response.status === 401) {
        authenticationService.loGout();
      }
  });

  }

  gettingPedidos(value){

    let data = {username: sessionStorage.getItem("username"),page:value,desde_1:"",hasta_1:""};
    fetch(this.state.serverUrl + "/api/getpedidospqr/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
        
      },
      body: JSON.stringify(data) 

  }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
              
              
              this.setState({listadoPedidos:data.data,totalPages:data.total_pages});
         
          });
      }
     
  });

  }


  onChangePqr(e){


    this.setState({ [e.target.name]: e.target.value });
  
    
  }

  onChangeCliente(e){


    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 15) {
        this.setState({ [e.target.name]: onlyNums });
    } else if (onlyNums.length > 5) {
        /*const number = onlyNums.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '($1) $2-$3'
        );*/
        this.setState({ cedula: onlyNums });
    }
 
    
  }

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });

  

  };

  selectedCLiente(id,value,nombrecliente, nitcliente){

    this.setState({selectedIdCostumer:value,clienteSeleccionado:nombrecliente,nitSeleccionado:nitcliente});
    

  }

  uploadPedido(){

    if(this.state.selectedIdCostumer!=""){
      

      if(this.state.file.name!=undefined){

            if(this.state.file.name.match(/.(jpg|jpeg|png|gif)$/i)){

              //if(this.state.latitude!="" && this.state.longitude!=""){

                this.setState({statusLoad:true,mensaje:""});
                let extensionFILe = this.state.file.name.substring(this.state.file.name.lastIndexOf('.') + 1).toLowerCase();
              
                let data = new FormData();
                data.append("upload", this.state.file);
                data.append("folder_name", sessionStorage.getItem("username"));
                data.append("extention", extensionFILe);
                data.append("fk_id_tienda_cliente", this.state.selectedIdCostumer);

                data.append("latitude", this.state.latitude);
                data.append("longitude", this.state.longitude);
                

                data.append("motivoPqr", this.state.motivoPqr);
                data.append("facturaPqr", this.state.facturaPqr);
                data.append("lotePqr", this.state.lotePqr);
                data.append("cantidadPqr", this.state.cantidadPqr);
       
                
              
                fetch(this.state.serverUrl+"/api/pedidospqr/", {
                  method: "POST",
                  //headers: {
                  //  Authorization: "Bearer " + this.state.token,
                  //},
                  body: data,
                })
                  .then((response) => {
          
                    
                    if(response.status==400){
          
                      alert("LA IMAGEN YA FUE CARGADA PREVIAMENTE");
          
                    }
                    if(response.status==201){
          
                        //console.log("imagen cargada");
                        this.setState({selectedIdCostumer:"",clienteSeleccionado:"",nitSeleccionado:""});
                        this.gettingPedidos(1);  
          
                    }
                    //this.setState({ imagename: cedula + ".jpg" });
                    this.setState({statusLoad:false});
                  })
                  .catch((err) => {
                    this.setState({ error: err });
                  });

              /*}else{

                this.setState({mensaje:"Active GPS y recargue pagina nuevamente"});
                
              }*/
            
        
            }else{
        
              
              this.setState({mensaje:"Seleccione una imagen para continuar"});
        
            }

      }else{

        this.setState({mensaje:"Cargue una pedido para continuar"});

      }   
      

    }else{
      this.setState({mensaje:"Seleccione un nit para continuar"});
     

    }
    

  }


  render() {


    return (
      <div>
    

        <Vista

          onFileChange={this.onFileChange}
          nitCliente={this.state.nitCliente}

          motivoPqr={this.state.motivoPqr}
          facturaPqr={this.state.facturaPqr}
          lotePqr={this.state.lotePqr}
          cantidadPqr={this.state.cantidadPqr}


          onChangeCliente={this.onChangeCliente}
          onChangePqr={this.onChangePqr}
          gettinCLientes={this.gettingClientes}
          allProductos={this.state.clientesTiendas}
          selectedCLiente={this.selectedCLiente.bind(this)}
          uploadPedido={this.uploadPedido}
          mensaje={this.state.mensaje}
          clienteSeleccionado={this.state.clienteSeleccionado}
          nitSeleccionado={this.state.nitSeleccionado}
          lat={this.state.latitude}
          lng={this.state.longitude}
          zoom={this.state.zoom}
          onChangeP1={this.onChangeP1}
          totalPages1={this.state.totalPagesUsuarios}
      
        ></Vista>
        <br /> <br />

     
       

        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> 

      

        {this.state.listadoPedidos.map((item) => {
       
          return (
            <div key={item.id}>
              
              <MediaCard
              
                urlImage={item.image_name}
                titulo={item.date_full}
                status={item.descargado}
                nit={item.tiendaCliente_nit}
                nombreCliente={item.tiendaCliente_nombre}
               // totaltratamientos={item.vecesVisto}
               // editar={this.handleClicks.bind(this, item.id)}
               eliminar={this.deletePedidos.bind(this, item.id)}

               nitcliente={item.tiendaCliente_nit}
               nombrecliente={item.tiendaCliente_nombre}
               motivo={item.motivo}
               factura={item.factura}
               lote={item.lote}
               cantidad={item.cantidad}

              ></MediaCard>
              <br /> <br />
              <Divider />
              <br /> <br />
            </div>
          );
        })}
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <center>
            <Pagination
              count={parseInt(this.state.totalPages)}
              color="primary"
              onChange={this.getclickedPagination}
            />
          </center>
        </Grid>


      </div>
    );
  }
}

export default Restart;

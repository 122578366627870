import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import React from "react";

const useStyles = makeStyles({});

export default function MediaCard(props) {
  const classes = useStyles();
  var styles1 = {
    width: "100%",
    height: "auto",
    table: {
      minWidth: 650,
    },
  };


  

  return (
    <Grid  container
    container
    direction="row"
    justify="center"
    alignItems="center">
    <Grid item xs={12} sm={6}>
      <center>
        <h2>DETALLE DE PEDIDO</h2>
      </center>
      <Paper className={classes.paper}>


      <Card className={classes.root}>
            <CardActionArea>
            <img src={props.urlImage} style={styles1} onClick={props.descargar} />

              <CardContent>
             

              <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            
           
          </TableRow>
        </TableHead>
        <TableBody>
         
            <TableRow key="52456">
              <TableCell >IMAGEN DESCARGADA</TableCell>
              <TableCell >{props.status==true?("SI"):("NO")}</TableCell>
            </TableRow>

            <TableRow key="52457">
              <TableCell >CEDULA</TableCell>
              <TableCell >{props.cedula}</TableCell>
            </TableRow>

            <TableRow key="52458">
              <TableCell >NOMBRE VENDEDOR</TableCell>
              <TableCell >{props.nombre}</TableCell>
            </TableRow>

            <TableRow key="52459">
              <TableCell >NIT CLIENTE</TableCell>
              <TableCell >{props.nitcliente}</TableCell>
            </TableRow>

            <TableRow key="52460">
              <TableCell >NOMBRE CLIENTE</TableCell>
              <TableCell >{props.nombrecliente}</TableCell>
            </TableRow>
     
        </TableBody>
      </Table>
    </TableContainer>

             
              </CardContent>

            </CardActionArea>

         
          </Card>
      
      
      </Paper>
    </Grid>






  </Grid>
  );
}

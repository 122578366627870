import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PlaceIcon from '@material-ui/icons/Place';
import StoreIcon from '@material-ui/icons/Store';
import ExtensionIcon from '@material-ui/icons/Extension';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';

import React from 'react';

import PropTypes from 'prop-types';

import {
  Route,
  NavLink,
  Redirect,
  Switch
} from "react-router-dom";

import {authenticationService} from '../../checkLoginStatus';

import Pedidos from "./pedidos/creacion_pedidos";

import Pqr from "./pedidos_pqr/creacion_pedidos";

import Historico from "./historico_pedidos/Pedidos";
import UploadVisitas from "./upload_visitas/creacion_visitas";
import CrearVisitas from "./crear_visitas/crear_visitas";
import CrearProspecto from "./crear_visitas/crear_prospecto";

import CrearVisitaProspecto from "./crear_visitas/crear_visita_prospecto";
/*
THE DRAWER WAS TAKEN FROM MATERIAL UI https://material-ui.com/components/drawers/#drawer
*/


function Copyright() {
    return (
      <Typography variant="body2" >
        
        <Link color="inherit" href="#">
          FITOMEDICS
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#1976d2" 
   
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
  root2: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    zIndex: theme.zIndex.drawer + 1,
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    textAlign: 'center',
    backgroundColor:'#1976d2',
    color: '#fafafa'
      
  },
  
}));

function Dasbhoard(props) {

  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List component="nav" aria-label="main mailbox folders">
                <ListItem button component={NavLink} to="/enduser/pedidos">
                 
                  <ListItemIcon>
                    <VpnKeyIcon />
                  </ListItemIcon>
                  <ListItemText primary="CargarPedido" />
                </ListItem>


                <ListItem button component={NavLink} to="/enduser/pqr">
                 
                 <ListItemIcon>
                   <VpnKeyIcon />
                 </ListItemIcon>
                 <ListItemText primary="CargarPQR" />
               </ListItem>

                <ListItem button
                component={NavLink} to="/admin/historico">
                  <ListItemIcon>
                    <PlaceIcon />
                  </ListItemIcon>
                  <ListItemText primary="Historico" />
                </ListItem>

                <ListItem button
                component={NavLink} to="/admin/crearprospecto">
                  <ListItemIcon>
                    <PlaceIcon />
                  </ListItemIcon>
                  <ListItemText primary="CrearProspecto" />
                </ListItem> 

                <ListItem button
                component={NavLink} to="/admin/crearvisita">
                  <ListItemIcon>
                    <PlaceIcon />
                  </ListItemIcon>
                  <ListItemText primary="CrearVisita ClienteFM" />
                </ListItem> 

              

                <ListItem button
                component={NavLink} to="/admin/crearvisitaprospecto">
                  <ListItemIcon>
                    <PlaceIcon />
                  </ListItemIcon>
                  <ListItemText primary="CrearVisita Prospecto" />
                </ListItem> 

                <ListItem button
                component={NavLink} to="/admin/uploadvisitas">
                  <ListItemIcon>
                    <PlaceIcon />
                  </ListItemIcon>
                  <ListItemText primary="CargarVisitas" />
                </ListItem>  
               
             
            
                <ListItem button onClick={authenticationService.loGout}>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Salir" />
                </ListItem>
                

          </List>
    </div>
  );

  return (
    <div className={classes.root2}>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            FITOMEDICS
          </Typography>
          <div className={classes.toolbarButtons}>
          <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => {
                  return authenticationService.loGout;
                }}
                color="inherit"
              >
                <ExitToAppIcon  />
              </IconButton>
          </div>  
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        
        

        <Switch>
        
          <Route path="/enduser/pedidos" component={Pedidos} />
          <Route path="/enduser/pqr" component={Pqr} />
          <Route path="/admin/historico" component={Historico} />  
          <Route path="/admin/crearvisita" component={CrearVisitas} /> 
          <Route path="/admin/crearprospecto" component={CrearProspecto} />
          <Route path="/admin/crearvisitaprospecto" component={CrearVisitaProspecto} /> 
          <Route path="/admin/uploadvisitas" component={UploadVisitas} />  
          <Redirect to="/enduser/pedidos" />
          
        </Switch>


     
      </main>
    </div>

    <footer className={classes.footer}>
    <Container maxWidth="sm">
    <Typography variant="h6" >CARGADOR DE PEDIDOS</Typography>
    <Copyright />
    </Container>
  </footer>
</div>

    
  );
}

Dasbhoard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.any,
};

export default Dasbhoard;

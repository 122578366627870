import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';




import clsx from "clsx";

import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Formulario(props) {
  const classes = useStyles();

  return (
  
      <Grid >
        <Grid item xs={12} >
          <center>
            <h2>PEDIDOS</h2>

        

          </center>
          <Paper className={classes.paper}>
            <div className={classes.root}>
              <div>
                <form validate="true" onSubmit={props.onsubmit} id="formulario">
                  <FormControl>
                   

                  <TextField
                      id="outlined-full-width"
                      label="NUMERO DE CELULAR"
                      autoFocus
                      required
                      style={{ margin: 8 }}
                      placeholder=""
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    
                      variant="outlined"
                      value={props.numeroCelular}
                      onChange={props.onChange}
                      name="numeroCelular"
                    />
                  
                  <br></br>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      REINICIAR SERVIDOR
                    </Button>

          

                    <br></br>


                    <List className={classes.root}>
    

                    {props.processedServices!==undefined?
                      
                      props.processedServices.map((item)=>(

              
                          <ListItem key={item.id}>
                          <ListItemAvatar>
                            <Avatar>
                              <CheckCircleIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={"Nombre jar : "+item.jarname}  secondary={"Nombre screen : "+item.screenName} />
                        </ListItem>

                      ))
                    
                    :""}
                    
                     </List>

                  </FormControl>
                </form>
              </div>
            </div>
          </Paper>
        </Grid>

   
      </Grid>
   
  );
}

import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import React, { Component } from "react";
import {authenticationService} from '../../../../checkLoginStatus';
import Formulario from "./formulario";




class TiendaClientes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nombreProducto: "",
      allServices: [],
      token: "",
      processedServices:[],
      mensaje:"",
      statusMensaje:false,
      statusLoad:false,
      intervalID:0,
      open:false,
      setOpen:true,
      nombreTienda:"",
      nitCliente:"",
      clientesTiendas:[],
      idCliente:0,
      direccion:"",
      ciudad:"",
      telefono:""
   
    };

    this.submitForm = this.submitForm.bind(this);
    
    this.handleClose = this.handleClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeGeneric = this.onChangeGeneric.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.eliminarVendedor = this.eliminarVendedor.bind(this);
    this.onChangeP1         = this.onChangeP1.bind(this);

    this.bridgeGettingCLientes = this.bridgeGettingCLientes.bind(this);
    this.gettingClientes    = this.gettingClientes.bind(this);

    this.selectedCLiente    = this.selectedCLiente.bind(this);
    this.creatingNewUser = this.creatingNewUser.bind(this);
  
    this.updateCliente = this.updateCliente.bind(this);
    this.removeItemTiendaCliente = this.removeItemTiendaCliente.bind(this);
    

 
  }


  onChangeP1(r,value){

    
    this.bridgeGettingCLientes(value);
}

  gettingClientes(){

    this.setState({mensaje:""});
    this.bridgeGettingCLientes(1);

}


onChangeGeneric(e){

  this.setState({ [e.target.name]: e.target.value });

}

removeItemTiendaCliente(i,item){


    
    fetch(this.state.serverUrl + "/api/tiendaclientes/?id="+item, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
      },
    }) .then(response => {
      if (response.status === 200) {
  
          response.json().then(data => {
            
            
            this.gettingClientes();
  
          });
      }
      else if (response.status === 400) {
       alert("NIT EN USO");
      }
  });

}

bridgeGettingCLientes(value){

 
  //
  fetch(this.state.serverUrl + "/api/tiendaclientes/?nit="+this.state.nitCliente+"&page="+value, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: "Bearer " + this.state.token,
    },
  }) .then(response => {
    if (response.status === 200) {

        response.json().then(data => {
          
          
          this.setState({
            clientesTiendas:data.data,
            totalPagesUsuarios: data.total_pages
          });

        });
    }
    else if (response.status === 401) {
      authenticationService.loGout();
    }
});

}


  submitForm(e) {
    e.preventDefault();

    if(this.state.nitCliente!="" && this.state.nombreTienda!=""){
      
      let flag=true;
      if(this.state.clientesTiendas.length>0){


          
          this.state.clientesTiendas.map(index=>{

          
            if(index.nit==this.state.nitCliente){

           
              //flag=false;
              //this.updatingUser();
            }

          })


          if(flag){

            
            this.creatingNewUser();
          }

      }else{

          
          this.creatingNewUser();

      }


     /* 

    */
    }else{

      alert("NO PUEDE DEJAR CAMPOS VACIOS");

    }

  }


  updateCliente(){


  
   
    if(this.state.idCliente>0){

      this.setState({statusLoad:true});
      let data = {id: this.state.idCliente,nombre:this.state.nombreTienda.toUpperCase(),direccion: this.state.direccion.toUpperCase(), ciudad: this.state.ciudad.toUpperCase(), telefono: this.state.telefono.toUpperCase()};
      fetch(this.state.serverUrl + "/api/tiendaclientes/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          //Authorization: "Bearer " + this.state.token,
          
        },
        body: JSON.stringify(data) 

    }) .then(response => {
        if (response.status === 200) {
  
          //this.getUsuarios(1); 
          this.setState({nitCliente:"",nombreTienda:"",idCliente:0, direccion:"",ciudad:"",telefono:""});
          this.setState({statusLoad:false});
          this.bridgeGettingCLientes(1);
        }

        if (response.status === 404) {
  
          alert("EL USUARIO YA EXISTE");
        }
       
    });

    }else{

        alert("SELECCIONE UN USUARIO DE LA TABLA");

    }
     /* */


  }

  creatingNewUser(){


    this.setState({statusLoad:true});

      let data = {nit: this.state.nitCliente,nombre:this.state.nombreTienda.toUpperCase(), direccion: this.state.direccion.toUpperCase(), ciudad: this.state.ciudad.toUpperCase(), telefono: this.state.telefono.toUpperCase()};
      fetch(this.state.serverUrl + "/api/tiendaclientes/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          //Authorization: "Bearer " + this.state.token,
          
        },
        body: JSON.stringify(data) 

    }) .then(response => {
        if (response.status === 201) {
          
          //alert("CLIENTE CREADO")
          //this.getUsuarios(1); 
          this.setState({nitCliente:"",nombreTienda:"", mensaje:"CLIENTE CREADO",direccion:"",ciudad:"",telefono:""});
          this.setState({statusLoad:false});
          this.bridgeGettingCLientes(1);
        }

        if (response.status === 404) {
  
          alert("EL USUARIO YA EXISTE");
        }
       
    });


  }


  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.state.token =tokenStorage;

    this.setState({

      serverUrl: sessionStorage.getItem("serverUrl"),
    });
 
    
  }




  handleClose(){
    this.setState({open:false});
    
  }

  onChange(e) {



    this.setState({ nombreTienda: e.target.value,mensaje:"" })


  }

  onChange2(e) {



    // this.setState({ [e.target.name]: e.target.value })


     const onlyNums = e.target.value.replace(/[^0-9]/g, '');
   if (onlyNums.length < 15) {
       this.setState({ [e.target.name]: onlyNums,mensaje:""  });
   } else if (onlyNums.length > 5) {
       /*const number = onlyNums.replace(
           /(\d{3})(\d{3})(\d{4})/,
           '($1) $2-$3'
       );*/
       this.setState({ cedula: onlyNums,mensaje:"" });
   }

 }

 eliminarVendedor(s,id){

  this.setState({statusLoad:true});
  fetch(this.state.serverUrl + "/api/createvendedor/?id="+id, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: "Bearer " + this.state.token,
      
    },
   

    }) .then(response => {
      
        if (response.status === 200) {
            
            response.json().then(data => {
              this.getUsuarios(1); 
              this.setState({statusLoad:false});
          
            });
        }
      
    });

 }

 selectedCLiente(id,value,nombrecliente, nitcliente,direccion,ciudad,telefono){

  

  this.setState({selectedIdCostumer:value,nombreTienda:nombrecliente,nitCliente:nitcliente,idCliente:value,direccion:direccion,ciudad:ciudad,telefono:telefono});

}

 onChange3(e,f){


  this.getUsuarios(f);
  
 }


 onChangeCliente(e){


  const onlyNums = e.target.value.replace(/[^0-9]/g, '');
  if (onlyNums.length < 15) {
      this.setState({ [e.target.name]: onlyNums });
  } else if (onlyNums.length > 5) {
      /*const number = onlyNums.replace(
          /(\d{3})(\d{3})(\d{4})/,
          '($1) $2-$3'
      );*/
      this.setState({ nitCliente: onlyNums });
  }

  
}


  render() {
    return (
      <div>


      
        <Formulario
          onsubmit={this.submitForm}
          handleClickOpen={this.handleClickOpen}
          nombreTienda={this.state.nombreTienda}
          nitCliente={this.state.nitCliente}
          direccion={this.state.direccion}
          ciudad={this.state.ciudad}
          telefono={this.state.telefono}


          processedServices={this.state.processedServices}
          onChangeGeneric={this.onChangeGeneric}
          onChange={this.onChange}
          onChange2={this.onChange2}
          onChange3={this.onChangeP1}
          eliminarVendedor={this.eliminarVendedor}
          
          totalPages1={this.state.totalPagesUsuarios}

          allProductos={this.state.clientesTiendas}
          onChangeCliente={this.onChangeCliente}
          gettinCLientes={this.gettingClientes}  
          updateCliente={this.updateCliente}
          removeItemTiendaCliente={this.removeItemTiendaCliente}
          selectedCLiente={this.selectedCLiente}
          
      
        ></Formulario>
        <br /> <br />

        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> 

        <center>
          <h2>{ this.state.mensaje}</h2>
        </center>


      </div>
    );
  }
}

export default TiendaClientes;

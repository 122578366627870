import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Mapa from './mapa';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';

import React from "react";

const useStyles = makeStyles({});

export default function MediaCard(props) {
  const classes = useStyles();
  var styles1 = {
    width: "100%",
    height: "auto",
    table: {
      minWidth: 650,
    },
  };


  

  return (
    <Grid container spacing={3}>
    <Grid item xs={6} >
      <center>
        <h2>DETALLE DE PEDIDO</h2>
      </center>
      <Paper className={classes.paper}>


      <Card className={classes.root}>
            <CardActionArea>
            <img src={props.urlImage} style={styles1} onClick={props.descargar} />

              <CardContent>
             

              <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            
           
          </TableRow>
        </TableHead>
        <TableBody>
         

            <TableRow key="524586">
              <TableCell >FECHA DE VISITA FUTURA</TableCell>
              <TableCell >{props.titulo}</TableCell>
            </TableRow>

            <TableRow key="5245s86">
              <TableCell >FECHA VISITA REALIZADA</TableCell>
              <TableCell >{props.revisado?props.date_of_load_meeting:""}</TableCell>
            </TableRow>

            <TableRow key="52457">
              <TableCell >CEDULA</TableCell>
              <TableCell >{props.cedula}</TableCell>
            </TableRow>

            <TableRow key="52458">
              <TableCell >NOMBRE VENDEDOR</TableCell>
              <TableCell >{props.nombre}</TableCell>
            </TableRow>

            <TableRow key="52459">
              <TableCell >NIT CLIENTE</TableCell>
              <TableCell >{props.nitcliente}</TableCell>
            </TableRow>

            <TableRow key="52460">
              <TableCell >NOMBRE CLIENTE</TableCell>
              <TableCell >{props.nombrecliente}</TableCell>
            </TableRow>

            <TableRow key="52460">
              <TableCell >ES PROSPECTO</TableCell>
              <TableCell >{props.esprospecto}</TableCell>
            </TableRow>

            <TableRow key="52461">
              <TableCell >CONSECUTIVO PEIDOD X PAGINA</TableCell>
              <TableCell >{props.pedido_number}</TableCell>
            </TableRow>
     
        </TableBody>
      </Table>
    </TableContainer>


     {props.item_prospecto?        
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>DATOS PROSPECTOS</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <br></br>

{props.array_prospectos!==undefined? (

<TableContainer component={Paper}>
<Table className={classes.table} aria-label="simple table">
<TableHead>
<TableRow>
<TableCell>NOMBRE TIENDA</TableCell>
<TableCell>NOMBRE PROPIETARIO</TableCell>
<TableCell>NOMBRE QUIEN ATIENDE</TableCell>
<TableCell>DIRECCION</TableCell>
<TableCell>TEL FIJO TIENDA</TableCell>
<TableCell>TEL CELULAR ENCARGADO</TableCell>
</TableRow>
</TableHead>

<TableBody>
{props.array_prospectos.map((row) => (
<TableRow key={row.id}>

<TableCell align="right">{row.nombre_tienda}</TableCell>
<TableCell align="right">{row.nombre_propieatario}</TableCell>
<TableCell align="right">{row.nombre_quien_atiende}</TableCell>
<TableCell align="right">{row.direccion}</TableCell>
<TableCell align="right">{row.tel_fijo_tienda}</TableCell>
<TableCell align="right">{row.tel_celular_encargado}</TableCell>

</TableRow>
))}
</TableBody>

</Table>
</TableContainer>

):("void")}
<br></br>
        </AccordionDetails>
      </Accordion>:""}

              </CardContent>

              

            </CardActionArea>

            <CardActions>
          
            <Button size="small" color="primary" onClick={props.descargar}>
              DESCARGAR
              </Button>
             
              
            </CardActions>
          </Card>
      
      
      </Paper>
    </Grid>

    <Dialog
        open={props.open}
        onClose={props.handleClose}
    
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Confirmación de descarga de pedido
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Presione sí para continuar,<br/> 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose} color="primary">
            No
          </Button>
          <Button onClick={props.handleCloseYes_cargaManual} color="primary">
            Sí
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={props.open2}
        onClose={props.handleClose}
    
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Confirmación de eliminación de pedido
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Presione sí para continuar,<br/> 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose} color="primary">
            No
          </Button>
          <Button onClick={props.handleClose_delete} color="primary">
            Sí
          </Button>
        </DialogActions>
      </Dialog>

    <Grid item xs={6} >
      <center>
        <h2>MAPA</h2>
      </center>
      <Paper className={classes.paper}>
      {props.latitude!=""? <Mapa lat={props.latitude} lng={props.longitude}></Mapa>:"void"}
      <br></br>
 
                <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
        <TableRow>
<TableCell>DATA</TableCell>

<TableCell>DATA</TableCell>
<TableCell align="left">&nbsp;&nbsp;&nbsp;CALIFICACION</TableCell>
</TableRow>
        </TableHead>
        <TableBody>
         
            <TableRow key="12456">
              <TableCell >LATITUD</TableCell>
              <TableCell >{props.latitude} </TableCell>
              <TableCell >_ </TableCell>
            </TableRow>

            <TableRow key="22457">
              <TableCell >LONGITUD</TableCell>
              <TableCell >{props.longitude}</TableCell>
              <TableCell >_</TableCell>
            </TableRow>


            <TableRow key="22458">
              <TableCell >VISITA PRESENCIAL</TableCell>
              <TableCell >{props.visita_presencial}</TableCell>
              <TableCell >{props.califi_visita_presencial}</TableCell>
            </TableRow>

            <TableRow key="22458">
              <TableCell >VISITA CON PEDIDO</TableCell>
              <TableCell >{props.visita_con_pedido}</TableCell>
              <TableCell >{props.califi_visita_con_pedido}</TableCell>
            </TableRow>

            <TableRow key="22458">
              <TableCell >CANTIDAD REFERENCIAS</TableCell>
              <TableCell >{props.cantidad_referencias}</TableCell>
              <TableCell >{props.califi_cantidad_referencias}</TableCell>
            </TableRow>

            <TableRow key="22458">
              <TableCell >CANTIDAD UNIDADES</TableCell>
              <TableCell >{props.cantidad_unidades}</TableCell>
              <TableCell >_</TableCell>
            </TableRow>


            <TableRow key="22458">
              <TableCell >FITOMILLONARIO</TableCell>
              <TableCell >{props.fitomillonario}</TableCell>
              <TableCell >{props.califi_fitomillonario}</TableCell>
            </TableRow>


            <TableRow key="22458">
              <TableCell >CARTERA</TableCell>
              <TableCell >{props.cartera}</TableCell>
              <TableCell >{props.califi_cartera}</TableCell>
            </TableRow>

            <TableRow key="22458">
              <TableCell >CALIFICACION CODIFI PRODUCTOS</TableCell>
              <TableCell >_</TableCell>
              <TableCell >{props.califi_codificacion_productos}</TableCell>
            </TableRow>

            <TableRow key="22458">
              <TableCell >CALIFICACION CAPACI PRODUCTOS</TableCell>
              <TableCell >_</TableCell>
              <TableCell >{props.califi_capacitacion_productos}</TableCell>
            </TableRow>

            <TableRow key="22458">
              <TableCell >TOTAL PUNTAJE</TableCell>
              <TableCell >_</TableCell>
              <TableCell >{props.sumatoria}</TableCell>
            </TableRow>
            
        
     
        </TableBody>
      </Table>
    </TableContainer>


    <br></br>

<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>CODIFICACION</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <br></br>

{props.arrayCodificacion!==undefined? (

<TableContainer component={Paper}>
<Table className={classes.table} aria-label="simple table">
<TableHead>
<TableRow>
<TableCell>PRODUCTO</TableCell>

</TableRow>
</TableHead>

<TableBody>
{props.arrayCodificacion.map((row) => (
<TableRow key={row.id}>

<TableCell align="right">{row.nombreproducto}</TableCell>

</TableRow>
))}
</TableBody>

</Table>
</TableContainer>

):("void")}
<br></br>
        </AccordionDetails>
      </Accordion>
      <br></br>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>CAPACITACION</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <br></br>

{props.arrayCapacitacion!==undefined? (

<TableContainer component={Paper}>
<Table className={classes.table} aria-label="simple table">
<TableHead>
<TableRow>
<TableCell>PRODUCTO</TableCell>

</TableRow>
</TableHead>

<TableBody>
{props.arrayCapacitacion.map((row) => (
<TableRow key={row.id}>

<TableCell align="right">{row.nombreproducto}</TableCell>

</TableRow>
))}
</TableBody>

</Table>
</TableContainer>

):("void")}
<br></br>
        </AccordionDetails>
      </Accordion>
     
            </Paper>
            </Grid>




  </Grid>
  );
}

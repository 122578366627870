import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Pagination from "@material-ui/lab/Pagination";

import MediaCard from "./Home";

import React, { Component } from "react";
import {authenticationService} from '../../../checkLoginStatus';


class CambioClave extends Component {
  constructor(props) {
    super(props);

    this.state = {

      token: "",
      processedServices:[],
      listadoPedidos: [],
      listadoUsuarios: [],
      mensaje:"",
      oldPassword:"",
      newpassword_1:"",
      newpassword_2:"",
      statusMensaje:false,
      username:""

   
    };


   

    this.onChange = this.onChange.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.onChange3 = this.onChange3.bind(this);
    
    this.submitForm = this.submitForm.bind(this);

 
  }

  
  onChange(e) {

    this.setState({ oldPassword: e.target.value,statusMensaje:false,mensaje:"" })

  }

  onChange2(e) {

    this.setState({ newpassword_1: e.target.value,statusMensaje:false,mensaje:"" })

  }

  onChange3(e) {

    this.setState({ newpassword_2: e.target.value,statusMensaje:false,mensaje:"" })

  }

 
  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.state.token =tokenStorage;
    this.state.username = sessionStorage.getItem("username");

    this.setState({

      serverUrl: sessionStorage.getItem("serverUrl"),
    });
  
  
   
  }



  submitForm(e) {
    e.preventDefault();

    if(this.state.oldpassword!="" && this.state.newpassword_1!="" && this.state.newpassword_2!=""){


        if(this.state.newpassword_1 == this.state.newpassword_2){


            let data = {oldpassword: this.state.oldPassword,newpassword:this.state.newpassword_1, username:this.state.username};
            fetch(this.state.serverUrl + "/api/changepassword/", {
                method: "POST",
                headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.state.token,
                
                },
                body: JSON.stringify(data) 

            }) .then(response => {
                if (response.status === 200) {
        
                
                this.setState({statusMensaje:true,mensaje:"CLAVE ACTUALIZADA",oldPassword:"",newpassword_1:"",newpassword_2:""});
                }

                if (response.status === 403) {
        
                
                    this.setState({statusMensaje:true,mensaje:"CLAVE ANTERIOR NO COINCIDE"});
                }

                if (response.status === 404) {
        
                alert("RECARGUE PAGINA NUEVAMENTE");
                }
            
            });


        }else{

            this.setState({statusMensaje:true,mensaje:"LAS CLAVES NO COINCIDEN, INTENTE NUEVAMENTE"});

        }



    } 

      
    //

    

    /*
    */
 

  }



  render() {
    return (
      <div>
    
            <MediaCard
              
                
             onsubmit={this.submitForm}

             oldPassword={this.state.oldPassword}
             newpassword_1={this.state.newpassword_1}
             newpassword_2={this.state.newpassword_2}   
             onChange={this.onChange}
             onChange2={this.onChange2}
             onChange3={this.onChange3}


            ></MediaCard>
      
      
      <center>
          <h2>{this.state.statusMensaje ? this.state.mensaje : ""}</h2>
        </center>

  

      </div>
    );
  }
}

export default CambioClave;

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';


import Pagination from "@material-ui/lab/Pagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import clsx from "clsx";

import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Formulario(props) {
  const classes = useStyles();

  return (
  
      <Grid >
        <Grid item xs={12} >
          <center>
            <h2>VENDEDORES</h2>
          </center>
          <Paper className={classes.paper}>
            <div className={classes.root}>
              <div>
                <form validate="true" onSubmit={props.onsubmit} id="formulario">
                  <FormControl>
                   

                  <TextField
                      id="outlined-full-width"
                      label="NOMBRE"
                      autoFocus
                      required
                      style={{ margin: 8 }}
                      placeholder=""
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    
                      variant="outlined"
                      value={props.nombreVendedor}
                      onChange={props.onChange}
                      name="nombre"
                    />

                    <TextField
                      id="outlined-full-width"
                      label="CEDULA"
                      autoFocus
                      required
                      style={{ margin: 8 }}
                      placeholder=""
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    
                      variant="outlined"
                      value={props.cedula}
                      onChange={props.onChange2}
                      name="cedula"
                    />
                  
                  <br></br>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      CREAR VENDEDOR
                    </Button>

          

                    <br></br>


                    {props.allProductos!==undefined? (

<TableContainer component={Paper}>
<Table className={classes.table} aria-label="simple table">
<TableHead>
<TableRow>
<TableCell>CEDULA</TableCell>
<TableCell align="right">NOMBRE VENDEDOR</TableCell>
<TableCell align="right">ACCION</TableCell>
</TableRow>
</TableHead>

<TableBody>
{props.allProductos.map((row) => (
<TableRow key={row.id}>
<TableCell component="th" scope="row">
{row.username}
</TableCell>
<TableCell align="right">{row.nombre_vendedor}</TableCell>
<TableCell align="right">
          <button
            onClick={(e) =>
              props.eliminarVendedor(e, row.id)
            }
          >
            ELiminar
          </button>
        </TableCell>
</TableRow>
))}
</TableBody>

</Table>
</TableContainer>

):("void")}



<Grid
container
spacing={0}
direction="column"
alignItems="center"
justify="center"
><br></br>
<center>
<Pagination
count={parseInt(props.totalPages1)}
color="primary"
onChange={props.onChange3}
/>
<br/>


</center>
</Grid>

                  </FormControl>
                </form>
              </div>
            </div>
          </Paper>
        </Grid>

   
      </Grid>
   
  );
}

import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import Container from '@material-ui/core/Container';


import clsx from "clsx";
import Pagination from "@material-ui/lab/Pagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import React from "react";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Formulario(props) {
  const classes = useStyles();

  function FormRow() {
    return (
    
      <Grid container spacing={2}>



        <Grid item xs={12}>

        

        <center>
            <h2>BUSQUEDA DE PEDIDOS</h2>
          </center>

        

          <Paper className={classes.paper}>

          <FormControl>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        Desde: <KeyboardDatePicker
          format="yyyy-MM-dd"
          value={props.date1}
          onChange={props.handleDateChange}
          
        />

        Hasta: <KeyboardDatePicker
          format="yyyy-MM-dd"
          value={props.date2}
          onChange={props.handleDateChange2}
          
        />
    
      </Grid>
    </MuiPickersUtilsProvider>

    
                  </FormControl><br></br><br></br><br></br><br></br>
        






<Button
                      size="large"
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={props.pendientes}
                    >
                      BUSCAR PEDIDOS
                    </Button>
          
          </Paper>




        </Grid>

     

        </Grid>
      
    );
  }

  return (
  
   
    <Grid container spacing={1}>
  <Grid container item xs={12} spacing={2}>
  

    <FormRow />
  </Grid>


</Grid>


      
  );
}

import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Container from '@material-ui/core/Container';
import Divider from "@material-ui/core/Divider";
import Pagination from "@material-ui/lab/Pagination";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import TableRow from '@material-ui/core/TableRow';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
}));
export default function Formulario(props) {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
  
      <Grid >
        <Grid item xs={12} >
          <center>
            <h2>CARGADOR DE SOPORTE</h2>
          </center>

          <Paper className={classes.paper}>
            <div className={classes.root}>
              <div>
              
                  <FormControl>
     
                  {props.listadoPedidos!==undefined? (

<TableContainer component={Paper}>
<Table className={classes.table} aria-label="simple table">
<TableHead>
<TableRow>
<TableCell>NIT</TableCell>
<TableCell align="right">CLIENTE</TableCell>
<TableCell align="right">FECHA</TableCell>

<TableCell align="right">PROSPECTO</TableCell>
<TableCell align="right">ACCION</TableCell>
</TableRow>
</TableHead>

<TableBody>
{props.listadoPedidos.map((row) => (
  row.cargado_soporte?"":<TableRow key={row.id}>
<TableCell component="th" scope="row">
{row.tiendaCliente_nit}
</TableCell>
<TableCell align="right">{row.tiendaCliente_nombre}</TableCell>
<TableCell align="right">{row.fecha_de_visita}</TableCell>

<TableCell align="right">{row.es_prospecto==true?<p>SI</p>:<p>NO</p>}</TableCell>
<TableCell align="right">
          <button
            onClick={(e) =>
              props.selectVisita(e, row.id,row.tiendaCliente_nombre,row.es_prospecto, row.fecha_de_visita)
            }
          >
            SELECCIONAR
          </button>
        </TableCell>
</TableRow>


))}
</TableBody>

</Table>
</TableContainer>

):("void")}




<Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
<Pagination
              count={props.count}
              color="primary"
              onChange={props.onChangep}
            />
             </Grid>
            <br></br>

                    <br></br>

                    {!props.esprospecto?
                    <Grid>
                         <p>SELECCIONADO: </p>{props.seleccionado}<p></p>

                        <FormGroup>

                        <FormControlLabel
                        control={<Switch checked={props.checkedVisitaPresencial} onChange={props.checkedVPresencial} />}
                        label="VISITA PRESENCIAL"
                        />
                        </FormGroup>
                        <TextField
                          id="outlined-full-width"
                          label="CANTIDAD REFERENCIAS"
                          required
                          style={{ margin: 8 }}
                          
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          value={props.cantidad_referencias}
                          onChange={props.onchangeNumeric}
                          name="cantidad_referencias"
                        />
                        <br></br>
                        <TextField
                          id="outlined-full-width"
                          label="CANTIDAD UNIDADES"
                          required
                          style={{ margin: 8 }}

                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          value={props.cantidad_unidades}
                          onChange={props.onchangeNumeric}
                          name="cantidad_unidades"
                        />


                        <br></br>

                        <FormGroup>


                        <FormControlLabel
                        control={<Switch checked={props.checkedFitomillonario} onChange={props.checkedFito} />}
                        label="FITOMILLONARIO"
                        />
                        <FormControlLabel
                        control={<Switch checked={props.checkedCartera} onChange={props.checkedCarte} />}
                        label="CARTERA"
                        />
                        </FormGroup>




                        <br></br>

                        <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}>CAPACITACION</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <br></br>

                        {props.array_productos_capacitacion!==undefined? (

                        <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                        <TableCell>PRODUCTO</TableCell>
                        <TableCell align="right">ACCION</TableCell>
                        </TableRow>
                        </TableHead>

                        <TableBody>
                        {props.array_productos_capacitacion.map((row) => (
                        <TableRow key={row.id}>

                        <TableCell align="right">{row.nombreproducto}</TableCell>
                        <TableCell align="right">
                        <button
                        onClick={(e) =>
                        props.removeItem_capacitacion(e, row.id)
                        }
                        >
                        Eliminar
                        </button>
                        </TableCell>
                        </TableRow>
                        ))}
                        </TableBody>

                        </Table>
                        </TableContainer>

                        ):("void")}
                        <br></br>
                        </AccordionDetails>
                        </Accordion>
                        <br></br>
                        <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                        <Typography className={classes.heading}>CODIFICACION</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <br></br>

                        {props.array_productos_codificacion!==undefined? (

                        <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                        <TableCell>PRODUCTO</TableCell>
                        <TableCell align="right">ACCION</TableCell>
                        </TableRow>
                        </TableHead>

                        <TableBody>
                        {props.array_productos_codificacion.map((row) => (
                        <TableRow key={row.id}>

                        <TableCell align="right">{row.nombreproducto}</TableCell>
                        <TableCell align="right">
                        <button
                        onClick={(e) =>
                        props.removeItem_codificacion(e, row.id)
                        }
                        >
                        Eliminar
                        </button>
                        </TableCell>
                        </TableRow>
                        ))}
                        </TableBody>

                        </Table>
                        </TableContainer>

                        ):("void")}
                        <br></br>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion disabled>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        >
                        <Typography className={classes.heading}>********************</Typography>
                        </AccordionSummary>
                        </Accordion>

                        <TextField
                          id="outlined-multiline-static"
                          label="CAPACITACION"
                          required
                          style={{margin:8}}


                          name="capacitacion"
                          variant="outlined"
                          value={props.capacitacion}
                          onChange={props.onChange}
                          onKeyPress={props.searchByName}
                          
                        />




                        <TextField
                          id="outlined-multiline-static"
                          label="CODIFICACION"
                          required
                          style={{margin:8}}
                          name="codificacion"
                          variant="outlined"
                          value={props.codificacion}
                          onChange={props.onChange}
                          onKeyPress={props.searchByName2}
                          
                        />

                        <Divider></Divider>


                        <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}>TABLA DE PRODUCTOS</Typography>
                        </AccordionSummary>
                        <center>{props.loaded? (
                        <Alert severity="success" >CARGADO!</Alert>
                        ):("")}
                        </center>
                        <br></br>
                        <AccordionDetails>


                        {props.allProductos!==undefined? (

                        <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                        <TableCell>PRODUCTO</TableCell>
                        <TableCell align="right">ACCION</TableCell>
                        </TableRow>
                        </TableHead>

                        <TableBody>
                        {props.allProductos.map((row) => (
                        <TableRow key={row.id}>

                        <TableCell align="right">{row.nombre_producto}</TableCell>
                        <TableCell align="right">
                        <button
                        onClick={(e) =>
                        props.selectedProducto(e, row.id,row.nombre_producto)
                        }
                        >
                        Seleccionar
                        </button>
                        </TableCell>
                        </TableRow>
                        ))}
                        </TableBody>

                        </Table>
                        </TableContainer>

                        ):("")}
                        </AccordionDetails>
                        </Accordion>






                        <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        ><br></br>
                        <center>
                        <Pagination
                        count={parseInt(props.totalPages1)}
                        color="primary"
                        onChange={props.onchangeTableProductos}
                        />
                        <br/>


                        </center>
                        </Grid>
                    </Grid>


                    :""}
                    <p>SELECCIONADO: </p>{props.seleccionado}<p></p>

                  

          
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={props.aperturaVisita}
                    >
                      APERTURA DE VISITA
                    </Button>      
                        <p></p>                                                                                                                 
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={props.uploadPedido}
                    >
                      ENVIAR
                    </Button>
               
                    <h2>{props.mensaje}</h2>
                  </FormControl>
              
              </div>
            </div>
          </Paper>
          <br></br>

         
        </Grid>

   
      </Grid>
   
  );
}





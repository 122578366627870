import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Pagination from "@material-ui/lab/Pagination";
import Vista from "./vista";
import MediaCard from "./listado_pedidos";

import React, { Component } from "react";
import {authenticationService} from '../../../../checkLoginStatus';
import Formulario from "./formulario";

class Pedidos extends Component {
  constructor(props) {
    super(props);

    this.state = {

      token: "",
      processedServices:[],
      listadoPedidos: [],
      listadoUsuarios: [],
      mensaje:"",
      statusMensaje:false,
      statusLoad:false,
      token:"",
      serverUrl:"",
      statusData:"",
      server_dt:"",
      open: false,
      open2: false,
      file: "",
      totalPages: 0,
      totalPagesUsuarios:0,
      selectedUsername:"",
      flag_pedidos_pendientes:false,
      date1:"",
      date2:"",
      nombreVendedor:"",
      global_id:0,
      gobal_imageUrl:"",

   
    };


    this.gettingPedidos     = this.gettingPedidos.bind(this);
    this.getclickedPagination = this.getclickedPagination.bind(this);
    this.deletePedidos      = this.deletePedidos.bind(this);
    this.getUsuarios        = this.getUsuarios.bind(this);
    this.selectvendedor     = this.selectvendedor.bind(this);
    this.onChangeP1         = this.onChangeP1.bind(this);
    this.pedidosPendientes  = this.pedidosPendientes.bind(this);
    this.pedidosPendientesBridge  = this.pedidosPendientesBridge.bind(this);
    this.downloadAction     = this.downloadAction.bind(this);
    this.handleDateChange   = this.handleDateChange.bind(this);
    this.handleDateChange2  = this.handleDateChange2.bind(this);
    this.informe_pedidos_solo_sabados = this.informe_pedidos_solo_sabados.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.handleCloseYes_cargaManual = this.handleCloseYes_cargaManual.bind(this);

    this.handleClose_delete = this.handleClose_delete.bind(this);


 
  }

  handleDateChange(value){

    this.setState({date1:value});

  }

  handleDateChange2(value){

    this.setState({date2:value});

  }

  downloadAction(id,imageUrl){
    
   this.setState({"open":true,"global_id":id,"gobal_imageUrl":imageUrl});

  }

  pedidosPendientesBridge(){

          this.pedidosPendientes(1);
  }


  informe_pedidos_solo_sabados(){
    this.setState({statusLoad:true});
      fetch(this.state.serverUrl + "/api/informesolosabados/", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
            //Authorization: "Bearer " + this.state.token,
          },
        }) .then(
          response => {
            response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
           
            a.href = url;
            //a.download = "INFORME--"+this.state.nombreVendedor+"--"+this.state.selectedUsername+".xlsx";
            a.download = "INFORME--PEDIDOS-SABADOS-2022.xlsx";
            a.click();
          });
          this.setState({statusLoad:false});
        });
 
  }


  pedidosPendientes(value){

    fetch(this.state.serverUrl + "/api/getpedidos/?page="+value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
      },
    }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
            
            this.setState({listadoPedidos:data.data,totalPages:data.total_pages,flag_pedidos_pendientes:false});

          });
      }
      else if (response.status === 401) {
        authenticationService.loGout();
      }
  });

  }

  getUsuarios(value){

          fetch(this.state.serverUrl + "/api/users/?page="+value, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
              //Authorization: "Bearer " + this.state.token,
            },
          }) .then(response => {
            if (response.status === 200) {

                response.json().then(data => {
                  
                  
                  this.setState({
                    listadoUsuarios:data.data,
                    totalPagesUsuarios: data.total_pages
                  });

                });
            }
            else if (response.status === 401) {
              authenticationService.loGout();
            }
        });

  }

  onChangeP1(event, value){

    this.getUsuarios(value);
    this.setState({listadoPedidos: []});
  }


  handleClose_delete(){


    this.setState({statusLoad:true});
    fetch(this.state.serverUrl + "/api/getpedidos/?id="+this.state.global_id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //Authorization: "Bearer " + this.state.token,
        
      },
     

      }) .then(response => {
         
          if (response.status === 200) {
              
              response.json().then(data => {
                  this.getclickedPagination(0,1);
                  //this.gettingPedidos(1);
                  this.setState({statusLoad:false,open2:false,global_id:0});
                  this.getUsuarios(1);
            
              });
          }
        
      });

  }


  deletePedidos(id, s){

       this.setState({global_id:id, open2:true});
    
  }


  


  getclickedPagination(event, value){
    

    if(this.state.flag_pedidos_pendientes){

      this.gettingPedidos(value);

    }else{

      this.pedidosPendientes(value);
    }

    
  }

  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.state.token =tokenStorage;
    
    this.setState({

      serverUrl: sessionStorage.getItem("serverUrl"),
    });
  
  
    this.getUsuarios(1);
  }

  gettingPedidos(value){

    this.setState({listadoPedidos:[]});
  
    let data = {};
    
    if(this.state.date1!="" && this.state.date2!=""){

      if(this.state.date1<=this.state.date2){

        var date1 = new Date(this.state.date1)
        var desde = date1.getFullYear()+ '-' +  (date1.getMonth() + 1)  + '-' +date1.getDate();  

        var date2 = new Date(this.state.date2)
        var hasta = date2.getFullYear()+ '-' +  (date2.getMonth() + 1)  + '-' +date2.getDate();  

    
        data = {username: this.state.selectedUsername,page:value,desde_1:desde,hasta_1:hasta};

        
      }else{

      
        this.setState({elmensaje:"LAS FECHA DESDE NO PUEDE SER MAYOR A LA FECHA HASTA ",statusLoad:false});
      
      }

    }else{

      data = {username: this.state.selectedUsername,page:value,desde_1:"",hasta_1:""};
    }

   fetch(this.state.serverUrl + "/api/getpedidos/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
        
      },
      body: JSON.stringify(data) 

  }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
              
              this.setState({listadoPedidos:data.data,totalPages:data.total_pages});
         
          });
      }
     
  });

  }



  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });

    let that = this;

    if(event.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)){
      this.setState({statusLoad:true});
      let extensionFILe = event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf('.') + 1).toLowerCase();
     
      let data = new FormData();
      data.append("upload", event.target.files[0]);
      data.append("folder_name", sessionStorage.getItem("username"));
      data.append("extention", extensionFILe);

      fetch(this.state.serverUrl+"/api/pedidos/", {
        method: "POST",
        //headers: {
        //  Authorization: "Bearer " + this.state.token,
        //},
        body: data,
      })
        .then((response) => {

           
           if(response.status==400){

            alert("LA IMAGEN YA FUE CARGADA PREVIAMENTE");

           }
           if(response.status==201){

              that.gettingPedidos(1);  

           }
          //this.setState({ imagename: cedula + ".jpg" });
          this.setState({statusLoad:false});
        })
        .catch((err) => {
          this.setState({ error: err });
        });

    }else{

      alert("Seleccione una imagen para continuar");

    }
    

  };

  selectvendedor(event, value,nombreven){

    

    
    this.setState({listadoPedidos:[],nombreVendedor:nombreven,selectedUsername:value,flag_pedidos_pendientes:true});


    setTimeout(() => {
      this.gettingPedidos(1);
   }, 500);
  }


  handleCloseYes_cargaManual(){


    
  window.open(this.state.gobal_imageUrl);

  this.setState({statusLoad:true});
  fetch(this.state.serverUrl + "/api/getpedidos/?id="+this.state.global_id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: "Bearer " + this.state.token,
      
    },
   

    }) .then(response => {
       
        if (response.status === 200) {
            
            response.json().then(data => {
                this.pedidosPendientes(1);
                this.setState({statusLoad:false,open:false,global_id:0,gobal_imageUrl:""});
                this.getUsuarios(1);
                
          
            });
        }
      
    });

  }

  handleClose(){


    this.setState({open:false,open2:false});
  }



  render() {
    return (
      <div>
    

        <Vista
          allProductos={this.state.listadoUsuarios}
          onFileChange={this.onFileChange}
          clickingRrow={this.selectvendedor}
          
          totalPages1={this.state.totalPagesUsuarios}
          onChangeP1={this.onChangeP1}
          date1={this.state.date1}
          date2={this.state.date2}
          pendientes={this.pedidosPendientesBridge}

          handleDateChange={this.handleDateChange}
          handleDateChange2={this.handleDateChange2}
          informe_pedidos_solo_sabados ={this.informe_pedidos_solo_sabados}

          selectedUser={this.state.nombreVendedor}
      
        ></Vista>
        <br /> <br />


<Grid
container
spacing={0}
direction="column"
alignItems="center"
justify="center"
><br></br>
<center>
<Pagination
count={parseInt(this.state.totalPagesUsuarios)}
color="primary"
onChange={this.onChangeP1}
/>
<br/>


</center>
</Grid>

        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> 

        <center>
          <h2>{this.state.mensaje}</h2>

          <p>{this.state.statusData}</p>
        </center>


        {this.state.listadoPedidos.map((item) => {
         
          return (
            <div key={item.id}>
              <MediaCard
              
                
                urlImage={item.image_name}
                titulo={item.date_full}
                status={item.descargado}
               // totaltratamientos={item.vecesVisto}
               // editar={this.handleClicks.bind(this, item.id)}
               cedula={item.username}
               nombre={item.vendedor}
               eliminar={this.deletePedidos.bind(this, item.id)}
               descargar={this.downloadAction.bind(this, item.id, item.image_name)}
               latitude={item.latitude}
               longitude={item.longitude}

               nitcliente={item.tiendaCliente_nit}
               nombrecliente={item.tiendaCliente_nombre}


               handleCloseYes_cargaManual={this.handleCloseYes_cargaManual}
               handleClose_delete={this.handleClose_delete}
               handleClose={this.handleClose}
               open={this.state.open}
               open2={this.state.open2}

              ></MediaCard>
              <br /> <br />
              <Divider />
              <br /> <br />
            </div>
          );
        })}
        {this.state.totalPages>0? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <center>
            <Pagination
              count={parseInt(this.state.totalPages)}
              color="primary"
              onChange={this.getclickedPagination}
            />
          </center>
        </Grid>
        ):("")}

      </div>
    );
  }
}

export default Pedidos;

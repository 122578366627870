import CircularProgress from "@material-ui/core/CircularProgress";

import Divider from "@material-ui/core/Divider";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";

import React, { Component } from "react";
import {authenticationService} from '../../../checkLoginStatus';
import Vista from "./vista_crear_prospecto";
import MediaCard from "./listado_pedidos";

import moment from "moment-timezone";
import Button from '@material-ui/core/Button';



class Restart extends Component {
  constructor(props) {
    super(props);

    this.state = {

      token: "",
      processedServices:[],
      listadoPedidos: [],
      mensaje:"",
      statusMensaje:false,
      statusLoad:false,
      token:"",
      serverUrl:"",
      statusData:"",
      server_dt:"",
      open: false,
      file: "",
      totalPages: 0,
      nitCliente:"",
      clientesTiendas:[],
      selectedIdCostumer:"",
      latitude: '',
      longitude: '',
      locked_gps: '',
      clienteSeleccionado:'',
      nitSeleccionado:'',
      nombretienda:'',
      nombrepropietario:'',
      nombrequienatienda:'',
      direccion:'',
      telfijotieda:'',
      telcelularencargado:'',
      zoom: 5,
      fecha : "2021-07-29",
      hora  : new Date('2014-08-18T21:11:54'),
      markerPoint: {
        x: 320,
        y: 192
      },
      lat: 39.9653301,
      lng: 32.7760817,
      

   
    };


    this.gettingPedidos     = this.gettingPedidos.bind(this);
    this.getclickedPagination = this.getclickedPagination.bind(this);
    this.eliminarVisita      = this.eliminarVisita.bind(this);
    this.onChangeCliente    = this.onChangeCliente.bind(this);

    this.gettingClientes    = this.gettingClientes.bind(this);
    this.selectedCLiente    = this.selectedCLiente.bind(this);
    this.uploadPedido       = this.uploadPedido.bind(this);
    this.updateGPS          = this.updateGPS.bind(this);
    this.bridgeGettingCLientes = this.bridgeGettingCLientes.bind(this);

    this.onChangeP1         = this.onChangeP1.bind(this);
    this.changeFecha        = this.changeFecha.bind(this);
    this.changeHour        = this.changeHour.bind(this);
 
  }


  onChangeP1(r,value){


      this.bridgeGettingCLientes(value);
  }

  changeFecha(r,v){

    
    const dateNew = moment.tz(v, "America/Bogota").format();
    

    this.setState({fecha:dateNew.toString()});
    //console.log("THE DATE..** "+this.state.fecha.toString());

  }

  changeHour(r,v){

    
    let number = moment(v, ["h:mm A"]).format("HH:mm");
    let newdate = new Date('2014-08-18T'+number+':00');
    this.setState({hora:newdate});
    

  }

  eliminarVisita(id, s){

        this.setState({statusLoad:true});
        fetch(this.state.serverUrl + "/api/deletevisita/?id="+s, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + this.state.token,
            
          },
         

          }) .then(response => {
             
              if (response.status === 200) {
                  
                  response.json().then(data => {
                      this.gettingPedidos(1);
                      this.setState({statusLoad:false});
                
                  });
              }
            
          });
    
  }


  getclickedPagination(event, value){

    this.gettingPedidos(value);
  }

  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.state.token =tokenStorage;
    
    this.setState({

      serverUrl: sessionStorage.getItem("serverUrl"),
    });
  
    this.gettingPedidos(1);  


    const location = window.navigator && window.navigator.geolocation
    
    if (location) {
      location.getCurrentPosition((position) => {
        
     
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          lat:position.coords.latitude,
          lng: position.coords.longitude
          
        })
      }, (error) => {
        //this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
      })
    }


    
  }




  gettingClientes(){

     
      this.bridgeGettingCLientes(1);

  }

  bridgeGettingCLientes(value){

   
    this.setState({mensaje:""});
    fetch(this.state.serverUrl + "/api/tiendaclientes/?nit="+this.state.nitCliente+"&page="+value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
      },
    }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
            
            
            this.setState({
              clientesTiendas:data.data,
              totalPagesUsuarios: data.total_pages
            });

          });
      }
      else if (response.status === 401) {
        authenticationService.loGout();
      }
  });

  }

  gettingPedidos(value){


    fetch(this.state.serverUrl + "/api/createprospecto/?nit="+sessionStorage.getItem("username")+"&page="+value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
        
      },
      
  }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
              
             
              this.setState({listadoPedidos:data.data,totalPages:data.total_pages});
         
          });
      }
     
  });

  }

  onChangeCliente(e){

    this.setState({ [e.target.name]:  e.target.value });
    
 
    
  }

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });

  

  };

  selectedCLiente(id,value,nombrecliente, nitcliente,locked_gps){

    this.setState({selectedIdCostumer:value,locked_gps:locked_gps});

  }

  updateGPS(){


    if(this.state.selectedIdCostumer!=""){

      const location = window.navigator && window.navigator.geolocation
    
      if (location) {
        location.getCurrentPosition((position) => {
          
       
          this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
  
          })
        }, (error) => {
          //this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
        })
      }

      let data =  {fk_id_prospecto:this.state.selectedIdCostumer,latitude:this.state.latitude,longitude:this.state.longitude,locked_gps:this.state.locked_gps};
                  
      fetch(this.state.serverUrl + "/api/createprospecto/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify(data) 

        }) .then(response => {
            if (response.status === 201) {
      
                alert("PROSPECTO ACTUALIZADO");
                this.gettingPedidos(1);
            }
    

          this.setState({statusLoad:false});
          
        });


    }else{

      alert("SELECCIONE PROSPECTO PRIMERO");

    }

  }


  uploadPedido(){
        
    //console.log("the hour-->"+this.state.hora);
    if(this.state.nombretienda!=""){
      
      if(this.state.nombrepropietario!=""){
      
        if(this.state.nombrequienatienda!=""){
      
          if(this.state.direccion!=""){
      
            if(this.state.telfijotieda!=""){
      
              if(this.state.telcelularencargado!=""){
      
                  let datas = new Date(this.state.hora);
                  var hours = datas.getHours();
                  var minutes = datas.getMinutes();
                  let endhour = hours+":"+minutes+":00";
  
  
                  this.setState({statusLoad:true,mensaje:""});
        
                  let newdates = new Date(this.state.fecha);
  
                  
                  let date1 =  moment.tz(newdates, "YYYY-MM-DD", "America/Bogota").local();
                 
  
                  var check = moment(date1, 'YYYY/MM/DD');
                  var month = check.format('MM');
                  var day   = check.format('DD');
                  var year  = check.format('YYYY');
  
                  let fulldate = year+"-"+month+"-"+day;
  
                  let fhora = fulldate+":"+endhour;
                  
                  
                  let data = {fk_id_account:sessionStorage.getItem("username"),nombre_tienda:this.state.nombretienda,nombre_propieatario:this.state.nombrepropietario,nombre_quien_atiende:this.state.nombrequienatienda,direccion:this.state.direccion,tel_fijo_tienda:this.state.telfijotieda,tel_celular_encargado:this.state.telcelularencargado, latitude:this.state.latitude,longitude:this.state.longitude};
                  
                  fetch(this.state.serverUrl + "/api/createprospecto/", {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      //Authorization: "Bearer " + this.state.token,
                      
                    },
                    body: JSON.stringify(data) 
            
                }) .then(response => {
                    if (response.status === 201) {
              
                        alert("PROSPECTO CREADO");
                        this.gettingPedidos(1);
                    }
                    if (response.status === 400) {
              
                      alert("PROSPECTO REPETIDO");
                      
                  }
            
                   this.setState({statusLoad:false});
                   
                });


             

              }else{
                this.setState({mensaje:"INGRESE CELULAR ENCARGADO TIENDA"});
              }
              

            }else{
              this.setState({mensaje:"INGRESE TELFIJO TIENDA"});
            }
             

          }else{
            this.setState({mensaje:"INGRESE DIRECCION TIENDA"});
          }
             

        }else{
          this.setState({mensaje:"INGRESE NOMBRE QUIEN ATIENDE"});
        }
             

      }else{
        this.setState({mensaje:"INGRESE NOMBRE PROPIETARIO"});
      }
             

    }else{
      this.setState({mensaje:"INGRESE NOMBRE TIENDA"});
    }
    

  }


  render() {


    return (
      <div>
    

        <Vista

          onFileChange={this.onFileChange}
          nitCliente={this.state.nitCliente}
          onChangeCliente={this.onChangeCliente}
          gettinCLientes={this.gettingClientes}
          allProductos={this.state.clientesTiendas}
          selectedCLiente={this.selectedCLiente.bind(this)}
          uploadPedido={this.uploadPedido}
          updateGPS = {this.updateGPS}
          mensaje={this.state.mensaje}
          clienteSeleccionado={this.state.clienteSeleccionado}
          nitSeleccionado={this.state.nitSeleccionado}
          lat={this.state.latitude}
          lng={this.state.longitude}
          zoom={this.state.zoom}
          onChangeP1={this.onChangeP1}
          totalPages1={this.state.totalPagesUsuarios}
          fecha={this.state.fecha}
          hora={this.state.hora}
          onchangeFecha = {this.changeFecha}
          onchangeHour  = {this.changeHour}
          listadoPedidos={this.state.listadoPedidos}
          eliminarVisita={this.eliminarVisita}
          nombretienda={this.state.nombretienda}
          nombrepropietario={this.state.nombrepropietario}
          nombrequienatienda={this.state.nombrequienatienda}
          direccion={this.state.direccion}
          telfijotieda={this.state.telfijotieda}
          telcelularencargado={this.state.telcelularencargado}

        ></Vista>
        <br /> <br />

     
       

        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> 

      
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <center>
            <Pagination
              count={parseInt(this.state.totalPages)}
              color="primary"
              onChange={this.getclickedPagination}
            />
          </center>
        </Grid>


      </div>
    );
  }
}

export default Restart;

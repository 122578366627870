import React, {Component } from 'react';

import { BrowserRouter, Switch, Route,Redirect } from 'react-router-dom';
import {  BrowserRouter as Router, Link,NavLink } from 'react-router-dom';

import {authenticationService} from './checkLoginStatus';

import Login from './Login/index';
import Logout from './views/Logout';
import AdminDashboard from './views/admin/AdminDashboard';
import SuperadminDashboard from './views/superadmin/SuperadminDashboard';
import EndUser from './views/enduser/AdminDashboard';


class App extends Component{


  /******ROLES 

  3: SUPERADMIN
  2: ADMIN
  1: ENDUSER


  ********** */

 componentDidMount() {

   sessionStorage.setItem("serverUrl", "https://pedidos.fitomedics.com");
  //sessionStorage.setItem("serverUrl", "http://localhost:8000");
  //sessionStorage.setItem("serverUrl", window.location.origin.toString()); //PRODUCTION  
}

  render(){
   

      if(!authenticationService.currentUserValue){
        return(
          <Router>
            <Switch>
            <Route exact={true} path="/login" component={Login}/>
           
            <Redirect to="/login" />
           
          </Switch>
        </Router>
        
        );
      }else{

              //*******************VALIDATING ROLES  

              if(authenticationService.getRol()=="SUPERADMIN"){
                  return(
                    <Router>
                      <SuperadminDashboard></SuperadminDashboard>
                  </Router>
                  );
              }

              if(authenticationService.getRol()=="ADMIN"){
                return(
                  <Router>
                    <AdminDashboard></AdminDashboard>
                  </Router>
                );
              }

              if(authenticationService.getRol()=="END_USER"){
                return(
                  <Router>
                    <EndUser></EndUser>
                  </Router>
                );
              }

            //*******************VALIDATING ROLES  

      }

  }

}



export default App;

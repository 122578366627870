import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import Divider from "@material-ui/core/Divider";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';


import Pagination from "@material-ui/lab/Pagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import clsx from "clsx";

import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Home(props) {
  const classes = useStyles();

  return (
  
      <Grid >
        <Grid item xs={12} >
          <center>
            <h2>CAMBIO DE CLAVE</h2>
          </center>
          <Paper className={classes.paper}>
            <div className={classes.root}>
              <div>
                <form validate="true" onSubmit={props.onsubmit} id="formulario">
                  <FormControl>
                   

                  <TextField
                      id="outlined-full-width"
                      label="CLAVE ACTUAL"
                      autoFocus
                      required
                      style={{ margin: 8 }}
                      placeholder=""
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="password"
                      variant="outlined"
                      value={props.oldPassword}
                      onChange={props.onChange}
                      name="oldPassword"
                    />

                    <TextField
                      id="outlined-full-width"
                      label="CLAVE NUEVA"
                      autoFocus
                      required
                      style={{ margin: 8 }}
                      placeholder=""
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="password"
                      variant="outlined"
                      value={props.newpassword_1}
                      onChange={props.onChange2}
                      name="newpassword_1"
                    />

                    <TextField
                      id="outlined-full-width"
                      label="REPETIR CLAVE NUEVA"
                      autoFocus
                      required
                      style={{ margin: 8 }}
                      placeholder=""
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="password"
                      variant="outlined"
                      value={props.newpassword_2}
                      onChange={props.onChange3}
                      name="newpassword_2"
                    />
                  
                  <br></br>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                      
                    >
                      ACTUALIZAR CLAVE
                    </Button>
                      <Divider></Divider>
                      <Divider></Divider>
                  
                  

                  </FormControl>
                </form>
              </div>
            </div>
          </Paper>
        </Grid>

   
      </Grid>
   
  );
}

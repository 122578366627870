import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import Divider from "@material-ui/core/Divider";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';


import Pagination from "@material-ui/lab/Pagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import clsx from "clsx";

import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Formulario(props) {
  const classes = useStyles();

  return (
  
      <Grid >
        <Grid item xs={12} >
          <center>
            <h2>CLIENTES</h2>
          </center>
          <Paper className={classes.paper}>
            <div className={classes.root}>
              <div>
                <form validate="true" onSubmit={props.onsubmit} id="formulario">
                  <FormControl>
                   

                  <TextField
                      id="outlined-full-width"
                      label="NOMBRE"
                      autoFocus
                      required
                      style={{ margin: 8 }}
                      placeholder=""
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    
                      variant="outlined"
                      value={props.nombreTienda}
                      onChange={props.onChange}
                      name="nombreTienda"
                    />

                    <TextField
                      id="outlined-full-width"
                      label="NIT"
                      autoFocus
                      required
                      style={{ margin: 8 }}
                      placeholder=""
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    
                      variant="outlined"
                      value={props.nitCliente}
                      onChange={props.onChange2}
                      name="nitCliente"
                    />

                    <TextField
                      id="outlined-full-width"
                      label="DIRECCION"
                      autoFocus
                      required
                      style={{ margin: 8 }}
                      placeholder=""
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    
                      variant="outlined"
                      value={props.direccion}
                      onChange={props.onChangeGeneric}
                      name="direccion"
                    />

                    <TextField
                      id="outlined-full-width"
                      label="CIUDAD"
                      autoFocus
                      required
                      style={{ margin: 8 }}
                      placeholder=""
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    
                      variant="outlined"
                      value={props.ciudad}
                      onChange={props.onChangeGeneric}
                      name="ciudad"
                    />

                  <TextField
                      id="outlined-full-width"
                      label="TELEFONO"
                      autoFocus
                      required
                      style={{ margin: 8 }}
                      placeholder=""
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    
                      variant="outlined"
                      value={props.telefono}
                      onChange={props.onChangeGeneric}
                      name="telefono"
                    />
                   
                  <br></br>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      CREAR CLIENTE
                    </Button>
                      <Divider></Divider>
                      <Divider></Divider>
                      <Divider></Divider><Divider></Divider><Divider></Divider><Divider></Divider><Divider></Divider>
                     
                      <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={props.updateCliente}
                    >
                      ACTUALIZAR CLIENTE
                    </Button><Divider></Divider>
                      <Divider></Divider>
                      <Divider></Divider><Divider></Divider><Divider></Divider><Divider></Divider><Divider></Divider>

                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={props.gettinCLientes}
                    >
                      BUSCAR CLIENTE
                    </Button><br></br>

                    </FormControl>
                </form>
                    <br></br>
                    <Divider></Divider>
                    <Divider></Divider>

                    {props.allProductos!==undefined? (

<TableContainer component={Paper}>
<Table className={classes.table} aria-label="simple table">
<TableHead>
<TableRow>
<TableCell className={classes.tableCell}>ID</TableCell>
<TableCell className={classes.tableCell}>NIT</TableCell>
<TableCell className={classes.tableCell} align="right">NOMBRE</TableCell>
<TableCell className={classes.tableCell} align="right">DIRECCION</TableCell>
<TableCell className={classes.tableCell} align="right">CIUDAD</TableCell>
<TableCell className={classes.tableCell} align="right">TELEFONO</TableCell>
<TableCell className={classes.tableCell} align="right">ACCION</TableCell>

</TableRow>
</TableHead>

<TableBody>
{props.allProductos.map((row) => (



<TableRow key={row.id} hover role="checkbox" onClick={(e) =>
props.selectedCLiente(e, row.id,row.nombre,row.nit,row.direccion,row.ciudad,row.telefono)
}>
<TableCell className={classes.tableCell} component="th" scope="row"  >
{row.id}
</TableCell>
<TableCell className={classes.tableCell} component="th" scope="row"  >
{row.nit}
</TableCell>
<TableCell className={classes.tableCell} align="right">{row.nombre}</TableCell>

<TableCell className={classes.tableCell} align="right">{row.direccion}</TableCell>
<TableCell className={classes.tableCell} align="right">{row.ciudad}</TableCell>
<TableCell className={classes.tableCell} align="right">{row.telefono}</TableCell>


<TableCell align="right">
          <button
            onClick={(e) =>
              props.removeItemTiendaCliente(e, row.id)
            }
          >
            Eliminar
          </button>
        </TableCell>

</TableRow>
))}
</TableBody>

</Table>
</TableContainer>

):("void")}



<Grid
container
spacing={0}
direction="column"
alignItems="center"
justify="center"
><br></br>
<center>
<Pagination
count={parseInt(props.totalPages1)}
color="primary"
onChange={props.onChange3}
/>
<br/>


</center>
</Grid>

               
              </div>
            </div>
          </Paper>
        </Grid>

   
      </Grid>
   
  );
}

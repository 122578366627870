import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import Container from '@material-ui/core/Container';


import clsx from "clsx";
import Pagination from "@material-ui/lab/Pagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import React from "react";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Formulario(props) {
  const classes = useStyles();

  function FormRow() {
    return (
    
      <Grid container spacing={2}>



        <Grid item xs={12}>

        

        <center>
            <h2>PQR</h2>
          </center>

        

          <Paper className={classes.paper}>

          <FormControl>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        Desde: <KeyboardDatePicker
          format="yyyy-MM-dd"
          value={props.date1}
          onChange={props.handleDateChange}
          
        />

        Hasta: <KeyboardDatePicker
          format="yyyy-MM-dd"
          value={props.date2}
          onChange={props.handleDateChange2}
          
        />
    
      </Grid>
    </MuiPickersUtilsProvider>

    
                  </FormControl><br></br><br></br><br></br><br></br>
        
          {props.allProductos!==undefined? (

<TableContainer component={Paper}>
<Table className={classes.table} aria-label="simple table">
<TableHead>
<TableRow>
<TableCell>CEDULA</TableCell>
<TableCell align="right">NOMBRE VENDEDOR</TableCell>
<TableCell align="right">PEDIDOS NUEVOS</TableCell>
<TableCell align="right">ACCION</TableCell>
</TableRow>
</TableHead>

<TableBody>
{props.allProductos.map((row) => (
<TableRow key={row.id}>
<TableCell component="th" scope="row">
{row.username}
</TableCell>
<TableCell align="right">{row.nombre_vendedor}</TableCell>
<TableCell align="right">{row.new_pedidos}</TableCell>
<TableCell align="right">
          <button
            onClick={(e) =>
              props.clickingRrow(e, row.username, row.nombre_vendedor)
            }
          >
            Seleccionar
          </button>
        </TableCell>
</TableRow>
))}
</TableBody>

</Table>
</TableContainer>

):("void")}





<Button
                      size="large"
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={props.pendientes}
                    >
                      TODOS LOS PQRS PENDIENTES
                    </Button>
            <br></br>
            <br></br>

            USUARIO SELECCIONADO<br></br>
            <br></br>
            {props.selectedUser}
          </Paper>




        </Grid>

     

        </Grid>
      
    );
  }

  return (
  
   
    <Grid container spacing={1}>
  <Grid container item xs={12} spacing={2}>
  

    <FormRow />
  </Grid>


</Grid>


      
  );
}

import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import React, { Component } from "react";
import {authenticationService} from '../../../../checkLoginStatus';
import Formulario from "./formulario";




class Servicios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nombreProducto: "",
      allServices: [],
      token: "",
      processedServices:[],
      mensaje:"",
      statusMensaje:false,
      statusLoad:false,
      intervalID:0,
      open:false,
      setOpen:true,
      nombreVendedor:"",
      cedula:"",
      tokenConfirm:"",
      allowedMobilephones:["3105451068","3155364706","3183913425","3105933507","3163625392","3172942666"],
      listadoUsuarios: [],

   
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.eliminarVendedor = this.eliminarVendedor.bind(this);
    this.onChange3 = this.onChange3.bind(this);

    

 
  }

  getUsuarios(value){

    fetch(this.state.serverUrl + "/api/users/?page="+value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
      },
    }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
            
            
            this.setState({
              listadoUsuarios:data.data,
              totalPagesUsuarios: data.total_pages
            });

          });
      }
      else if (response.status === 401) {
        authenticationService.loGout();
      }
  });

}

  submitForm(e) {
    e.preventDefault();

    this.setState({statusLoad:true});

      let data = {username: this.state.cedula,nombre_vendedor:this.state.nombreVendedor};
      fetch(this.state.serverUrl + "/api/createvendedor/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          //Authorization: "Bearer " + this.state.token,
          
        },
        body: JSON.stringify(data) 

    }) .then(response => {
        if (response.status === 201) {
  
          this.getUsuarios(1); 
          this.setState({username:"",nombreVendedor:""})
          this.setState({statusLoad:false});
        }

        if (response.status === 404) {
  
          alert("EL USUARIO YA EXISTE");
        }
       
    });
    //

    

    /*
    */
 

  }



  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.state.token =tokenStorage;

    this.setState({

      serverUrl: sessionStorage.getItem("serverUrl"),
    });
  
    this.getUsuarios(1); 
    
  }

  getServices() {
   
    let counter=0;

    try {
      this.state.intervalID = setInterval(async () => {
        
        
        fetch(this.state.serverUrl + "/v1/getservicestatus", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.state.token,
          },
        }) .then(response => {
          if (response.status === 200) {

              response.json().then(data => {

                this.setState({processedServices:data});
              
                if(this.state.allServices.length==data.length){
                 
                  clearInterval(this.state.intervalID);
                  
                  this.setState({statusLoad:false,statusMensaje:true,mensaje:data.length+" SERVICIOS LANZADOS"});

                }
              });
          }
        
      });



      }, 5000);
    } catch(e) {
      //console.log(e);
    }
 
  }



  handleClickOpen(){

    

    //console.log("value "+this.state.tokenConfirm);

    fetch(this.state.serverUrl + "/v1/verficationcode/"+this.state.tokenConfirm+"/no", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
     
    }).then((response) => {
      if (response.status === 200) {
        
         this.setState({processedServices:[],statusLoad:true,statusMensaje:true,mensaje:"INCIANDO DESPLIEGUE DE SERVICIOS",open:false,tokenConfirm:''});

         response.json().then(data => {

         
            this.setState({allServices:data});
            
            this.getServices();
          
        });

        if (response.status === 302) {
          
          this.setState({processedServices:[],statusLoad:true,statusMensaje:true,mensaje:"LOS SERVICIOS ESTAN SIENDO LANZADOS, ESPERE HASTA TERMINAR EL PROCESO"});
          response.json().then(data => {

           
            this.setState({allServices:data});
            
            this.getServices();
          
        });

         
       }

        
      }
      
      if (response.status === 208) {
        
        this.setState({statusMensaje:true,mensaje:"INTENTE NUEVAMENTE",open:false,tokenConfirm:''});
       
     }
    });
    
    
  }

  handleClose(){
    this.setState({open:false});
    
  }

  onChange(e) {



    this.setState({ nombreVendedor: e.target.value })


  }

  onChange2(e) {



    // this.setState({ [e.target.name]: e.target.value })


     const onlyNums = e.target.value.replace(/[^0-9]/g, '');
   if (onlyNums.length < 15) {
       this.setState({ [e.target.name]: onlyNums });
   } else if (onlyNums.length > 5) {
       /*const number = onlyNums.replace(
           /(\d{3})(\d{3})(\d{4})/,
           '($1) $2-$3'
       );*/
       this.setState({ cedula: onlyNums });
   }

 }

 eliminarVendedor(s,id){

  this.setState({statusLoad:true});
  fetch(this.state.serverUrl + "/api/createvendedor/?id="+id, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: "Bearer " + this.state.token,
      
    },
   

    }) .then(response => {
       
        if (response.status === 200) {
            
            response.json().then(data => {
              this.getUsuarios(1); 
              this.setState({statusLoad:false});
          
            });
        }
      
    });

 }

 onChange3(e,f){


  this.getUsuarios(f);
  
 }



  render() {
    return (
      <div>


      
        <Formulario
          onsubmit={this.submitForm}
          handleClickOpen={this.handleClickOpen}
          nombreVendedor={this.state.nombreVendedor}
          cedula={this.state.cedula}
          processedServices={this.state.processedServices}
          onChange={this.onChange}
          onChange2={this.onChange2}
          onChange3={this.onChange3}
          eliminarVendedor={this.eliminarVendedor}
          
          totalPages1={this.state.totalPagesUsuarios}

          allProductos={this.state.listadoUsuarios}
          
      
        ></Formulario>
        <br /> <br />

        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> 

        <center>
          <h2>{this.state.statusMensaje ? this.state.mensaje : ""}</h2>
        </center>


      </div>
    );
  }
}

export default Servicios;

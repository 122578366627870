import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Container from '@material-ui/core/Container';
import Divider from "@material-ui/core/Divider";
import Pagination from "@material-ui/lab/Pagination";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import TableRow from '@material-ui/core/TableRow';

import clsx from "clsx";

import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
}));
export default function Formulario(props) {
  const classes = useStyles();

  return (
  
      <Grid >
        <Grid item xs={12} >
          <center>
            <h2>CARGADOR DE PEDIDOS</h2>
          </center>
          <Paper className={classes.paper}>
            <div className={classes.root}>
              <div>
              
                  <FormControl>
                    <TextField
                      id="outlined-full-width"
                      label=""
                      required
                      style={{ margin: 8 }}
                      placeholder="NIT CLIENTE"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={props.nitCliente}
                      onChange={props.onChangeCliente}
                      name="nitCliente"
                    />
                    <br />

                    <Divider />
                    <br />
                  
                  
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={props.gettinCLientes}
                    >
                      BUSCAR CLIENTE
                    </Button><br></br>
                 

                    <br></br>

                      {props.allProductos!==undefined? (

                        <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>NIT</TableCell>
            <TableCell className={classes.tableCell} align="right">NOMBRE</TableCell>
   
          </TableRow>
        </TableHead>

        <TableBody>
          {props.allProductos.map((row) => (
            <TableRow key={row.id} hover role="checkbox" onClick={(e) =>
              props.selectedCLiente(e, row.id,row.nombre,row.nit)
            }>
              <TableCell className={classes.tableCell} component="th" scope="row"  >
                {row.nit}
              </TableCell>
              <TableCell className={classes.tableCell} align="right">{row.nombre}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
       
      </Table>
      </TableContainer>

                      ):("void")}

                   
                    
                   <strong>CLIENTE  : {props.clienteSeleccionado}</strong>

                   
                   <strong>NIT  : {props.nitSeleccionado}</strong>
                      <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        ><br></br>
          <center>
            <Pagination
              count={parseInt(props.totalPages1)}
              color="primary"
              onChange={props.onChangeP1}
            />
          </center>
        </Grid>

        <br/>
        <input
                      accept="image/*"
                      className={classes.input}
                      id="icon-button-file"
                      type="file"
                      onChange={props.onFileChange}
                    />
                    <br></br>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={props.uploadPedido}
                    >
                      CARGAR PEDIDO
                    </Button>
               
                    <h2>{props.mensaje}</h2>
                  </FormControl>
              
              </div>
            </div>
          </Paper>
        </Grid>

   
      </Grid>
   
  );
}





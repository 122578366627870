import React,{Component} from 'react';

import {Redirect} from 'react-router-dom';

import ReactDOM from 'react-dom';

import {authenticationService} from '../checkLoginStatus';

import SignIn from './components/Login';


class Login extends React.Component{

    constructor(props){
        super(props)
/*
        if(authenticationService.currentUserValue){
            if(authenticationService.getRol()==3){
                window.location.href = "/superadmin/";
            }
            if(authenticationService.getRol()==2){
                window.location.href = "/admin/";
            }
        }*/
        
        this.state = {

            username:'',
            password:'',
            

        }
        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        
    }


    onChange(e){
        console.log("printing from here..");
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    submitForm(e){ 

        e.preventDefault();
        const user = {username: this.state.username, password: this.state.password};    
        
        
      
        fetch(sessionStorage.getItem("serverUrl")+'/api/login/', {    
            method: 'POST',    
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)    
        })    
        .then(response => response.json()) 
        .then(data => {
            console.log(data);
          


            if(data.rol==="SUPERADMIN"){ //superadmin dasbhboard

                sessionStorage.setItem("token", data.access);
                //sessionStorage.setItem("name", data.infoEmpresa.nombreUsuario);
                sessionStorage.setItem("rol", data.rol);
                sessionStorage.setItem("username", data.username)
            
                this.setState({isAuthenticated: true}); 
              
                window.location.href = "/superadmin/";
               
            }
            if(data.rol==="ADMIN"){ //admin dashboard

                console.log("we have come here..");
                sessionStorage.setItem("token", data.access);
                //sessionStorage.setItem("name", data.infoEmpresa.nombreUsuario);
                sessionStorage.setItem("rol", data.rol);
                sessionStorage.setItem("username", data.username)

                this.setState({isAuthenticated: true}); 
                window.location.href = "/admin/";
            }
            if(data.rol==="END_USER"){ //admin enduser

                console.log("we have come here..***");
                sessionStorage.setItem("token", data.access);
                //sessionStorage.setItem("name", data.infoEmpresa.nombreUsuario);
                sessionStorage.setItem("rol", data.rol);
                sessionStorage.setItem("username", data.username)

                this.setState({isAuthenticated: true}); 
                window.location.href = "/enduser/";

            }
              

        }) 
            .catch(err => console.error(err)) 


    }

    render(){

        /*if(this.state.isAuthenticated){


            return <Redirect to="/superadmin"/>

        }*/

        return(
            <div>
        
                 {/*
                <h1>login</h1>
                <form onSubmit={this.submitForm}>

                    <input type="text" placeholder="username" name="username" value={this.state.username} onChange={this.onChange}></input>
                    <br/>
                    <input type="password" placeholder="password" name="password" value={this.state.password} onChange={this.onChange}></input>
                    <br/>
                    <input type="submit" ></input>

                </form>
                    */
                 }

             <SignIn onChange={this.onChange} username={this.username} password={this.password} onsubmit={this.submitForm}/>
                          
            </div>
        )

    }
}

export default Login;
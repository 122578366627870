import CircularProgress from "@material-ui/core/CircularProgress";

import Divider from "@material-ui/core/Divider";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";

import React, { Component } from "react";
import {authenticationService} from '../../../checkLoginStatus';
import Vista from "./vista";
import MediaCard from "./listado_pedidos";

import moment from "moment-timezone";
import Button from '@material-ui/core/Button';



class Restart extends Component {
  constructor(props) {
    super(props);

    this.state = {

      token: "",
      processedServices:[],
      listadoPedidos: [],
      mensaje:"",
      statusMensaje:false,
      statusLoad:false,
      flagOnce:true,
      fecha_de_visita:"",
      token:"",
      serverUrl:"",
      statusData:"",
      server_dt:"",
      open: false,
      file: "",
      totalPages: 0,
      nitCliente:"",
      clientesTiendas:[],
      selectedIdCostumer:"",
      latitude: '',
      longitude: '',
      clienteSeleccionado:'',
      nitSeleccionado:'',
      fk_id_cliente:'',
      detalle:'',
      zoom: 5,
      seleccionado:'',
      markerPoint: {
        x: 320,
        y: 192
      },
      lat: 39.9653301,
      lng: 32.7760817,
      cantidad_referencias:0,
      cantidad_unidades:0,
      capacitacion:"", 
      codificacion:"", 
      fitomillonario:"",
      productName:"",
      capacitacion_or_codificacion:"",
      array_productos_capacitacion:[],
      array_productos_codificacion:[],
      loaded:false,
      checkedFitomillonario:false,
      checkedCartera:false,
      checkedVisitaPresencial:false,
      checkedVisitaPedido:false,
      esprospecto:true
      
      

   
    };


    this.gettingPedidos     = this.gettingPedidos.bind(this);
    this.getclickedPagination = this.getclickedPagination.bind(this);
    this.deletePedidos      = this.deletePedidos.bind(this);
    this.onChangeCliente    = this.onChangeCliente.bind(this);

    this.gettingClientes    = this.gettingClientes.bind(this);
    this.selectedCLiente    = this.selectedCLiente.bind(this);
    this.uploadPedido       = this.uploadPedido.bind(this);
    this.bridgeGettingCLientes = this.bridgeGettingCLientes.bind(this);

    this.onChangeP1         = this.onChangeP1.bind(this);
    this.selectVisita       = this.selectVisita.bind(this);
    this.onChange = this.onChange.bind(this);  
    this.onchangeNumeric = this.onchangeNumeric.bind(this); 
    this.searchingByProductName = this.searchingByProductName.bind(this);
    this.getProductosBYName = this.getProductosBYName.bind(this);
    this.searchingByProductName2 = this.searchingByProductName2.bind(this);
    this.selectedProducto = this.selectedProducto.bind(this); 
    this.removeItem_capacitacion = this.removeItem_capacitacion.bind(this);
    this.removeItem_codificacion = this.removeItem_codificacion.bind(this);
    this.checkedFito             = this.checkedFito.bind(this);
    this.checkedCarte          = this.checkedCarte.bind(this);

    this.checkedVPresencial          = this.checkedVPresencial.bind(this);
    this.checkedVConPedido          = this.checkedVConPedido.bind(this);
    this.onchangeTableProductos     = this.onchangeTableProductos.bind(this);

    this.finalUPLOADconpedido = this.finalUPLOADconpedido.bind(this);

    this.aperturaVisita = this.aperturaVisita.bind(this);

  }


  aperturaVisita(){

    
    if(this.state.fk_id_cliente!=""){



      var q = new Date();
      var m = q.getMonth();
      var d = q.getDay();
      var y = q.getFullYear();

      let systemdate = moment.tz(moment(), 'America/Bogota').format('YYYY-MM-DD');

      let date1 =  moment.tz(this.state.fecha_de_visita, "YYYY-MM-DD", "America/Bogota").local();
               

      var check = moment(date1, 'YYYY/MM/DD');
      var month = check.format('MM');
      var day   = check.format('DD');
      var year  = check.format('YYYY');

      let fulldate = year+"-"+month+"-"+day;

      if(fulldate==systemdate){


        if(this.state.flagOnce){

         
          this.setState({flagOnce:false});
  
                  //console.log(" -- "+this.state.fk_id_cliente)
                  let data = {idcliente: this.state.fk_id_cliente,username:sessionStorage.getItem("username")};
                  fetch(this.state.serverUrl + "/api/updateaperturavisita/", {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json"
                      //Authorization: "Bearer " + this.state.token,
                    },
                    body: JSON.stringify(data) 
                  }) .then(response => {
  
                  
                        if (response.status === 200) {
                            
                            response.json().then(data => {
  
                              if(data.answer=="OK"){
  
                                alert("APERTURA DE VISITA INICIADA")
                                this.setState({flagOnce:true});
  
                              }
                              if(data.status=="405"){
  
                                alert(data.answer);
                                this.setState({flagOnce:true});
                              }
                              if(data.status=="406"){
  
                                alert(data.answer);
                                this.setState({flagOnce:true});
                              }
                              //this.setState({statusLoad:false,open:false,global_id:0,gobal_imageUrl:""});
                          
                            });
                        }
                        if (response.status === 205) {
                            
                            //console.log(response);
                            alert("Ya fue tomada la fecha");
                            this.setState({flagOnce:true});
                        }
                      
                    });
  
          }

         
      }else{

        alert("FECHA DE VISITA SELECCIONADA ES MAYOR A "+systemdate);
      }  

    }


  }


  checkedVConPedido(){

    if(this.state.checkedVisitaPedido){

        this.setState({checkedVisitaPedido:false});
    }else{

       this.setState({checkedVisitaPedido:true});
    }
  }  


  checkedVPresencial(){

    if(this.state.checkedVisitaPresencial){

        this.setState({checkedVisitaPresencial:false});
    }else{

       this.setState({checkedVisitaPresencial:true});
    }
  }  

  checkedFito(){

    if(this.state.checkedFitomillonario){

        this.setState({checkedFitomillonario:false});
    }else{

       this.setState({checkedFitomillonario:true});
    }
  }

  checkedCarte(){

    if(this.state.checkedCartera){

        this.setState({checkedCartera:false});
    }else{

       this.setState({checkedCartera:true});
    }
  }

  removeItem_capacitacion(s,id){

    let counter=0;
    let tempArray = this.state.array_productos_capacitacion;
    for( var i = 0; i < tempArray.length; i++){ 
      
        if ( tempArray[i].id === id) { 
    
            tempArray.splice(i, 1); 
        }
    
    }

    this.setState({array_productos_capacitacion:tempArray});

  }

  removeItem_codificacion(s,id){

    let counter=0;
    let tempArray = this.state.array_productos_codificacion;
    for( var i = 0; i < tempArray.length; i++){ 
      
        if ( tempArray[i].id === id) { 
    
            tempArray.splice(i, 1); 
        }
    
    }

    this.setState({array_productos_codificacion:tempArray});

  }

  selectedProducto(s,id, nombreproducto){

   
    if(this.state.capacitacion_or_codificacion=="codificacion"){

     
      let flag_1 = true;
      this.state.array_productos_codificacion.map((item=>{

          if(item.id!==id){

          }else{

            flag_1 = false;
            alert("ELEMENTO REPETIDO");         
          }

      }))


      if(flag_1){

        
            let producto = {id:id,nombreproducto:nombreproducto}
            this.state.array_productos_codificacion.push(producto);
            this.setState({array_productos_codificacion:this.state.array_productos_codificacion,loaded:true});
            let that = this;

            setTimeout((that) => {
              this.setState({loaded:false});
            }, 2000); //miliseconds

      }

      if(this.state.array_productos_codificacion.length==0){

        
        let producto = {id:id,nombreproducto:nombreproducto}
            this.state.array_productos_codificacion.push(producto);
            this.setState({array_productos_codificacion:this.state.array_productos_codificacion,loaded:true});
            let that = this;

            setTimeout((that) => {
              this.setState({loaded:false});
            }, 2000); //miliseconds

      }
      
      

     

    }else{


      
      let flag_1 = true;
      this.state.array_productos_capacitacion.map((item=>{

          if(item.id!==id){
            
          }else{

            flag_1 = false;
            alert("ELEMENTO REPETIDO");         
          }

      }))


      if(flag_1){

        
            let producto = {id:id,nombreproducto:nombreproducto}
            this.state.array_productos_capacitacion.push(producto);
            this.setState({array_productos_capacitacion:this.state.array_productos_capacitacion,loaded:true});
            let that = this;

            setTimeout((that) => {
              this.setState({loaded:false});
            }, 2000); //miliseconds

      }

      if(this.state.array_productos_capacitacion.length==0){

        
        let producto = {id:id,nombreproducto:nombreproducto}
            this.state.array_productos_capacitacion.push(producto);
            this.setState({array_productos_capacitacion:this.state.array_productos_capacitacion,loaded:true});
            let that = this;

            setTimeout((that) => {
              this.setState({loaded:false});
            }, 2000); //miliseconds

      }


 

    }

  }

  searchingByProductName2(e){

    if (e.key === 'Enter') {
      

      if(this.state.codificacion.length>0){

        this.setState({capacitacion:"",listadoUsuarios:[],productName:this.state.codificacion,capacitacion_or_codificacion:"codificacion"});
        this.getProductosBYName(1);
        //this.getAllProductos(0);
      }

    }
    
  }

  searchingByProductName(e){

    if (e.key === 'Enter') {
      

      if(this.state.capacitacion.length>0){

        this.setState({codificacion:"",listadoUsuarios:[],productName:this.state.capacitacion,capacitacion_or_codificacion:"capacitacion"});
        this.getProductosBYName(1);
        //this.getAllProductos(0);
      }

    }
    
  }


  getProductosBYName(value){

    let data = {nombre_producto: this.state.productName,page:value};
    fetch(this.state.serverUrl + "/api/getproductosbyname/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
      },
      body: JSON.stringify(data) 
    }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
            
            
            this.setState({
              listadoUsuarios:data.data,
              totalPagesUsuarios: data.total_pages
            });

          });
      }
      else if (response.status === 401) {
        authenticationService.loGout();
      }
  });

}

  onchangeNumeric(e){

    const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
        this.setState({[e.target.name]:e.target.value});
      }

  }

  onChange(e) {

    if(e.target.name=="capacitacion"){

      this.setState({codificacion:""});

    }else{

      this.setState({capacitacion:""});
    }
    this.setState({[e.target.name]:e.target.value.toUpperCase()});
    
  }

  selectVisita(r,value,v2,v3, fecha_de_visita){

    this.setState({fecha_de_visita:fecha_de_visita,fk_id_cliente:value,seleccionado:v2,esprospecto:v3});
  }


  onChangeP1(r,value){


      this.bridgeGettingCLientes(value);
  }

  deletePedidos(id, s){

        this.setState({statusLoad:true});
        fetch(this.state.serverUrl + "/api/getpedidos/?id="+id, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + this.state.token,
            
          },
         

          }) .then(response => {
             
              if (response.status === 200) {
                  
                  response.json().then(data => {
                      this.gettingPedidos(1);
                      this.setState({statusLoad:false});
                
                  });
              }
            
          });
    
  }


  getclickedPagination(event, value){

    this.gettingPedidos(value);
  }

  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.state.token =tokenStorage;
    
    this.setState({

      serverUrl: sessionStorage.getItem("serverUrl"),
    });
  
    this.gettingPedidos(1);  


    const location = window.navigator && window.navigator.geolocation
    
    if (location) {
      location.getCurrentPosition((position) => {
        
     
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          lat:position.coords.latitude,
          lng: position.coords.longitude
          
        })
      
      }, (error) => {
        //this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
      })
    }


    
  }




  gettingClientes(){

     
      this.bridgeGettingCLientes(1);

  }

  bridgeGettingCLientes(value){

   
    this.setState({mensaje:""});
    fetch(this.state.serverUrl + "/api/tiendaclientes/?nit="+this.state.nitCliente+"&page="+value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
      },
    }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
            
            
            this.setState({
              clientesTiendas:data.data,
              totalPagesUsuarios: data.total_pages
            });

          });
      }
      else if (response.status === 401) {
        authenticationService.loGout();
      }
  });

  }

  gettingPedidos(value){

    fetch(this.state.serverUrl + "/api/totalvisitas/?nit="+sessionStorage.getItem("username")+"&page="+value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
        
      },
      
  }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
              
              this.setState({listadoPedidos:data.data,totalPages:data.total_pages});
         
          });
      }
     
  });

  }

  onChangeCliente(e){


    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 15) {
        this.setState({ [e.target.name]: onlyNums });
    } else if (onlyNums.length > 5) {
        /*const number = onlyNums.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '($1) $2-$3'
        );*/
        this.setState({ cedula: onlyNums });
    }
 
    
  }

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });

  

  };

  selectedCLiente(id,value,nombrecliente, nitcliente){

    this.setState({selectedIdCostumer:value,clienteSeleccionado:nombrecliente,nitSeleccionado:nitcliente});
    

  }
  onchangeTableProductos(e,value){

    this.getProductosBYName(value);
  }



  finalUPLOADconpedido(extensionFILe,file){


    if(this.state.flagOnce){

        this.setState({flagOnce:false});
        
        let data = new FormData();
        data.append("upload",file);
        data.append("folder_name", sessionStorage.getItem("username"));
        data.append("extention", extensionFILe);
        data.append("fk_id_visitasasesorestoclientestienda", this.state.fk_id_cliente);

        data.append("latitude", this.state.latitude);
        data.append("longitude", this.state.longitude);
        data.append("detail", this.state.detalle);

        data.append("cantidad_referencias", this.state.cantidad_referencias);
        data.append("cantidad_unidades", this.state.cantidad_unidades);
      

        data.append("fitomillonario", this.state.checkedFitomillonario?"SI":"NO");
        data.append("cartera", this.state.checkedCartera?"SI":"NO");

        data.append("visita_presencial", this.state.checkedVisitaPresencial?"SI":"NO");
        data.append("visita_con_pedido", this.state.checkedVisitaPedido?"SI":"NO");
        
        let arrarypCodificacion = [];
        this.state.array_productos_codificacion.map((item=>{

          arrarypCodificacion.push(item.id);

        }))

        data.append("codificacion", "["+arrarypCodificacion.toString()+"]");
        
        let arraycapacitacion = [];
        this.state.array_productos_capacitacion.map((item=>{

          arraycapacitacion.push(item.id);

        }))

        data.append("capacitacion", "["+arraycapacitacion.toString()+"]");
        
      
        fetch(this.state.serverUrl+"/api/uploadsoportevisitas/", {
          method: "POST",
          //headers: {
          //  Authorization: "Bearer " + this.state.token,
          //},
          body: data,
        })
          .then((response) => {

            
            if(response.status==405){

              alert("LA FECHAS NO COINCIDEN date_of_load_meeting, CIERRE SESSION Y EL NAVEGADOR, INTENTE DE NUEVO EN UN 1 MINUTO, CONTACTE AL ADMINSITRADOR SI EL ERROR CONTINUA");
              this.setState({flagOnce:true});

            }

            if(response.status==400){

              alert("LA IMAGEN YA FUE CARGADA PREVIAMENTE");
              this.setState({flagOnce:true});

            }
            if(response.status==205){

              alert("CLICK EN INICIO DE APERTURA PRIMERO");
              this.setState({flagOnce:true});

            }
            if(response.status==201){

                //console.log("imagen cargada");
                this.setState({fk_id_cliente:"",detalle:"",clienteSeleccionado:"",nitSeleccionado:"",seleccionado:"",cantidad_referencias:0,cantidad_unidades:0,array_productos_capacitacion:[],array_productos_codificacion:[],fitomillonario:""});
                alert("VISITA ENVIADA");
                window.location.reload();
                //this.gettingPedidos(1);  

            }
            //this.setState({ imagename: cedula + ".jpg" });
            this.setState({statusLoad:false});
            this.setState({flagOnce:true});
          })
          .catch((err) => {
            this.setState({ error: err });
          });
      
        }

  }


  uploadPedido(){

    if(this.state.fk_id_cliente!=""){
      

                let flagConpedido = false;
                let extensionFILe ="";
                let file = this.state.file;
                if(false){

                  flagConpedido = true;
                }else{
                  file = "none";
                  extensionFILe = "none";
                }

                if(flagConpedido){


                      if(this.state.file.name!=undefined){

                        if(this.state.file.name.match(/.(jpg|jpeg|png|gif)$/i)){
                  
                            extensionFILe = this.state.file.name.substring(this.state.file.name.lastIndexOf('.') + 1).toLowerCase();
                            this.finalUPLOADconpedido(extensionFILe,file);

                        }else{

                          this.setState({mensaje:"Seleccione una imagen para continuar"});
                        }
                        
                      }else{

                          this.setState({mensaje:"Cargue una pedido para continuar"});
                  
                      }


                }else{

                  this.finalUPLOADconpedido(extensionFILe,file);

                }    

      

    }else{
      this.setState({mensaje:"Seleccione un nit para continuar"});
     

    }
    

  }


  render() {


    return (
      <div>
    

        <Vista

          onFileChange={this.onFileChange}
          nitCliente={this.state.nitCliente}
          onChangeCliente={this.onChangeCliente}
          gettinCLientes={this.gettingClientes}
          
          selectedCLiente={this.selectedCLiente.bind(this)}
          uploadPedido={this.uploadPedido}
          mensaje={this.state.mensaje}
          clienteSeleccionado={this.state.clienteSeleccionado}
          nitSeleccionado={this.state.nitSeleccionado}
          lat={this.state.latitude}
          lng={this.state.longitude}
          zoom={this.state.zoom}
          onChangeP1={this.onChangeP1}
          totalPages1={this.state.totalPagesUsuarios}
          listadoPedidos={this.state.listadoPedidos}
          selectVisita={this.selectVisita}
          seleccionado={this.state.seleccionado}
          detalle={this.state.detalle}
          onchangeNumeric={this.onchangeNumeric}
          onChange={this.onChange}

          cantidad_referencias={this.state.cantidad_referencias}
          cantidad_unidades={this.state.cantidad_unidades}
          capacitacion={this.state.capacitacion}
          codificacion={this.state.codificacion}
          fitomillonario={this.state.fitomillonario}
          count={parseInt(this.state.totalPages)}
          onChangep={this.getclickedPagination}
          searchByName={this.searchingByProductName}
          searchByName2={this.searchingByProductName2}
          allProductos={this.state.listadoUsuarios}
          array_productos_capacitacion={this.state.array_productos_capacitacion}
          array_productos_codificacion={this.state.array_productos_codificacion}
          selectedProducto={this.selectedProducto}
          loaded={this.state.loaded}

          removeItem_capacitacion = {this.removeItem_capacitacion}
          removeItem_codificacion = {this.removeItem_codificacion}
          checkedFitomillonario={this.state.checkedFitomillonario}
          checkedFito={this.checkedFito}
          checkedCartera = {this.state.checkedCartera}
          checkedCarte={this.checkedCarte}

          checkedVConPedido={this.checkedVConPedido}
          checkedVisitaPedido={this.state.checkedVisitaPedido}

          checkedVPresencial={this.checkedVPresencial}
          checkedVisitaPresencial={this.state.checkedVisitaPresencial}
          onchangeTableProductos={this.onchangeTableProductos}
          esprospecto={this.state.esprospecto}
          aperturaVisita={this.aperturaVisita}
      
        ></Vista>
        <br /> <br />

     
       

        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> 

      
    


      </div>
    );
  }
}

export default Restart;

import CircularProgress from "@material-ui/core/CircularProgress";

import Divider from "@material-ui/core/Divider";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";

import React, { Component } from "react";
import {authenticationService} from '../../../checkLoginStatus';
import Vista from "./vista";
import MediaCard from "./listado_pedidos";

import moment from "moment-timezone";
import Button from '@material-ui/core/Button';



class Restart extends Component {
  constructor(props) {
    super(props);

    this.state = {

      token: "",
      processedServices:[],
      listadoPedidos: [],
      mensaje:"",
      statusMensaje:false,
      statusLoad:false,
      token:"",
      serverUrl:"",
      statusData:"",
      server_dt:"",
      open: false,
      file: "",
      totalPages: 0,
      nitCliente:"",
      clientesTiendas:[],
      selectedIdCostumer:"",
      latitude: '',
      longitude: '',
      clienteSeleccionado:'',
      nitSeleccionado:'',
      zoom: 5,
      fecha : moment.tz(moment(), 'America/Bogota').local().format('YYYY-MM-DD'),
      hora  : new Date('2014-08-18T21:11:54'),
      markerPoint: {
        x: 320,
        y: 192
      },
      lat: 39.9653301,
      lng: 32.7760817,
      

   
    };


    this.gettingPedidos     = this.gettingPedidos.bind(this);
    this.getclickedPagination = this.getclickedPagination.bind(this);
    this.eliminarVisita      = this.eliminarVisita.bind(this);
    this.onChangeCliente    = this.onChangeCliente.bind(this);

    this.gettingClientes    = this.gettingClientes.bind(this);
    this.selectedCLiente    = this.selectedCLiente.bind(this);
    this.uploadPedido       = this.uploadPedido.bind(this);
    this.bridgeGettingCLientes = this.bridgeGettingCLientes.bind(this);

    this.updateGPS       = this.updateGPS.bind(this);

    this.onChangeP1         = this.onChangeP1.bind(this);
    this.changeFecha        = this.changeFecha.bind(this);
    this.changeHour        = this.changeHour.bind(this);
 
  }


  onChangeP1(r,value){


      this.bridgeGettingCLientes(value);
  }

  changeFecha(r,v){

    
    const dateNew = moment.tz(v, "America/Bogota").format();
    //console.log(dateNew)

    this.setState({fecha:dateNew.toString()});
    //console.log("THE DATE..** "+this.state.fecha.toString());

  }

  changeHour(r,v){

    //console.log("priuting .."+v);
    let number = moment(v, ["h:mm A"]).format("HH:mm");
    let newdate = new Date('2014-08-18T'+number+':00');
    this.setState({hora:newdate});
    

  }

  updateGPS(){

    if(this.state.selectedIdCostumer!=""){

      const location = window.navigator && window.navigator.geolocation
    
      if (location) {
        location.getCurrentPosition((position) => {
          
       
          this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
  
          })
        }, (error) => {
          //this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
        })
      }

      let data =  {fk_id_prospecto:this.state.selectedIdCostumer,latitude:this.state.latitude,longitude:this.state.longitude,locked_gps:this.state.locked_gps};
                  
      fetch(this.state.serverUrl + "/api/updategps/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify(data) 

        }) .then(response => {
            if (response.status === 201) {
      
                alert("TIENDA ACTUALIZADA");
                
            }
    

          this.setState({statusLoad:false});
          
        });


    }else{

      alert("SELECCIONE PROSPECTO PRIMERO");

    }

  }

  eliminarVisita(id, s){

        this.setState({statusLoad:true});
        fetch(this.state.serverUrl + "/api/deletevisita/?id="+s, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + this.state.token,
            
          },
         

          }) .then(response => {
             //console.log(response);
              if (response.status === 200) {
                  
                  response.json().then(data => {
                      this.gettingPedidos(1);
                      this.setState({statusLoad:false});
                
                  });
              }
            
          });
    
  }


  getclickedPagination(event, value){

    this.gettingPedidos(value);
  }

  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.state.token =tokenStorage;
    
    this.setState({

      serverUrl: sessionStorage.getItem("serverUrl"),
    });
  
    this.gettingPedidos(1);  


    const location = window.navigator && window.navigator.geolocation
    
    if (location) {
      location.getCurrentPosition((position) => {
        //console.log(position.coords.longitude);
     
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          lat:position.coords.latitude,
          lng: position.coords.longitude
          
        })
      }, (error) => {
        //this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
      })
    }


    
  }




  gettingClientes(){

     
      this.bridgeGettingCLientes(1);

  }

  bridgeGettingCLientes(value){

   
    this.setState({mensaje:""});
    fetch(this.state.serverUrl + "/api/tiendaclientes/?nit="+this.state.nitCliente+"&page="+value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
      },
    }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
            
            
            this.setState({
              clientesTiendas:data.data,
              totalPagesUsuarios: data.total_pages
            });

          });
      }
      else if (response.status === 401) {
        authenticationService.loGout();
      }
  });

  }

  gettingPedidos(value){


    fetch(this.state.serverUrl + "/api/totalvisitas/?nit="+sessionStorage.getItem("username")+"&page="+value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
        
      },
      
  }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
              
              //console.log(data.data);
              this.setState({listadoPedidos:data.data,totalPages:data.total_pages});
         
          });
      }
     
  });

  }

  onChangeCliente(e){


    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 15) {
        this.setState({ [e.target.name]: onlyNums });
    } else if (onlyNums.length > 5) {
        /*const number = onlyNums.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '($1) $2-$3'
        );*/
        this.setState({ cedula: onlyNums });
    }
 
    
  }

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });

  

  };

  selectedCLiente(id,value,nombrecliente, nitcliente,locked_gps){

    this.setState({selectedIdCostumer:value,clienteSeleccionado:nombrecliente,nitSeleccionado:nitcliente,locked_gps:locked_gps});
    

  }

  uploadPedido(){
    
    if(this.state.selectedIdCostumer!=""){
      

      var q = new Date();
      var m = q.getMonth();
      var d = q.getDay();
      var y = q.getFullYear();

      let systemdate = moment.tz(moment(), 'America/Bogota').format('YYYY-MM-DD')



                let datas = new Date(this.state.hora);
                var hours = datas.getHours();
                var minutes = datas.getMinutes();
                //let endhour = hours+":"+minutes+":00";

                let endhour = "00:00:00";  

                this.setState({statusLoad:true,mensaje:""});
      
                let newdates = new Date(this.state.fecha);

                
                let date1 =  moment.tz(newdates, "YYYY-MM-DD", "America/Bogota").local();
               

                var check = moment(date1, 'YYYY/MM/DD');
                var month = check.format('MM');
                var day   = check.format('DD');
                var year  = check.format('YYYY');

                let fulldate = year+"-"+month+"-"+day;

                let fhora = fulldate+":"+endhour;

            
                if(fulldate>=systemdate){

                        let data = {fk_id_prospectos:"PROPESCTO_",es_prospecto:"false",fecha: fulldate,fecha_hora:fhora,fk_id_tienda_cliente:this.state.selectedIdCostumer,folder_name:sessionStorage.getItem("username"),hora:endhour};
                        //console.log(data);
                        fetch(this.state.serverUrl + "/api/createvisita/", {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            //Authorization: "Bearer " + this.state.token,
                            
                          },
                          body: JSON.stringify(data) 
                  
                      }) .then(response => {
                          if (response.status === 201) {
                    
                              alert("VISITA CREADA");
                              this.gettingPedidos(1);
                          }
                  
                        this.setState({statusLoad:false});
                        
                      });

                }else{

                    alert("seleccione una fecha igual o mayor a la de hoy");
                }
                             
                
     
      

    }else{
      this.setState({mensaje:"Seleccione un nit para continuar"});
     

    }
    

  }


  render() {


    return (
      <div>
    

        <Vista

          onFileChange={this.onFileChange}
          nitCliente={this.state.nitCliente}
          onChangeCliente={this.onChangeCliente}
          gettinCLientes={this.gettingClientes}
          allProductos={this.state.clientesTiendas}
          selectedCLiente={this.selectedCLiente.bind(this)}
          uploadPedido={this.uploadPedido}
          mensaje={this.state.mensaje}
          clienteSeleccionado={this.state.clienteSeleccionado}
          nitSeleccionado={this.state.nitSeleccionado}
          lat={this.state.latitude}
          lng={this.state.longitude}
          zoom={this.state.zoom}
          onChangeP1={this.onChangeP1}
          totalPages1={this.state.totalPagesUsuarios}
          fecha={this.state.fecha}
          hora={this.state.hora}
          onchangeFecha = {this.changeFecha}
          onchangeHour  = {this.changeHour}
          listadoPedidos={this.state.listadoPedidos}
          eliminarVisita={this.eliminarVisita}
          updateGPS={this.updateGPS}

        ></Vista>
        <br /> <br />

     
       

        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> 

      


        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <center>
            <Pagination
              count={parseInt(this.state.totalPages)}
              color="primary"
              onChange={this.getclickedPagination}
            />
          </center>
        </Grid>


      </div>
    );
  }
}

export default Restart;

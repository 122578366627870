import Logout from "./views/Logout";

const currentUser = sessionStorage.getItem("token");

export const authenticationService = {
    
    
    get currentUserValue () { 
    
        if(currentUser==null){

            //  
            return false;

        }else{
            
            return true;
        } 
    
    },

    getRol(){

        return sessionStorage.getItem("rol");
  
    },

    loGout(){

        sessionStorage.clear();
        window.location.href = "/";
    }
};

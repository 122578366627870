import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import React, { Component } from "react";
import {authenticationService} from '../../../../checkLoginStatus';
import Formulario from "./formulario";




class Productos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nombreProducto: "",
      allServices: [],
      token: "",
      processedServices:[],
      mensaje:"",
      statusMensaje:false,
      statusLoad:false,
      intervalID:0,
      open:false,
      setOpen:true,
      nombreVendedor:"",
      cedula:"",
      tokenConfirm:"",
      allowedMobilephones:["3105451068","3155364706","3183913425","3105933507","3163625392","3172942666"],
      listadoUsuarios: [],
      idProductoGlobal:0

   
    };


    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.eliminarVendedor = this.eliminarVendedor.bind(this);
    this.onChange3 = this.onChange3.bind(this);

    this.actualizarproducto = this.actualizarproducto.bind(this);
    this.eliminarproducto = this.eliminarproducto.bind(this);

    this.createproducto = this.createproducto.bind(this);

    

 
  }

  getUsuarios(value){

    fetch(this.state.serverUrl + "/api/productosfitomedics/?page="+value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: "Bearer " + this.state.token,
      },
    }) .then(response => {
      if (response.status === 200) {

          response.json().then(data => {
            
            
            this.setState({
              listadoUsuarios:data.data,
              totalPagesUsuarios: data.total_pages
            });

          });
      }
      else if (response.status === 401) {
        authenticationService.loGout();
      }
  });

}

createproducto() {
    
  if(this.state.nombreVendedor!="") {

  
    this.setState({statusLoad:true});

      let data = {nombre_producto: this.state.nombreVendedor};
      fetch(this.state.serverUrl + "/api/productosfitomedics/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          //Authorization: "Bearer " + this.state.token,
          
        },
        body: JSON.stringify(data) 

    }) .then(response => {
        if (response.status === 201) {
  
          this.getUsuarios(1); 
          this.setState({username:"",nombreVendedor:""})
          this.setState({statusLoad:false});
        }

        if (response.status === 400) {
  
          alert("EL PRODUCTO YA EXISTE");
          this.setState({statusLoad:false});
        }
       
    });
    //

  }else{

    alert("INGRESE UN NOMBRE DE PRODUCTO PRIMERO");

  }

    /*
    */
 

  }



  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.state.token =tokenStorage;

    this.setState({

      serverUrl: sessionStorage.getItem("serverUrl"),
    });
  
    this.getUsuarios(1); 
    
  }

  getServices() {
   
    let counter=0;

    try {
      this.state.intervalID = setInterval(async () => {
        
        
        fetch(this.state.serverUrl + "/v1/getservicestatus", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.state.token,
          },
        }) .then(response => {
          if (response.status === 200) {

              response.json().then(data => {

                this.setState({processedServices:data});
              
                if(this.state.allServices.length==data.length){
                 
                  clearInterval(this.state.intervalID);
                  
                  this.setState({statusLoad:false,statusMensaje:true,mensaje:data.length+" SERVICIOS LANZADOS"});

                }
              });
          }
        
      });



      }, 5000);
    } catch(e) {
      //console.log(e);
    }
 
  }



  handleClickOpen(){

    

    //console.log("value "+this.state.tokenConfirm);

    fetch(this.state.serverUrl + "/v1/verficationcode/"+this.state.tokenConfirm+"/no", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
     
    }).then((response) => {
      if (response.status === 200) {
        
         this.setState({processedServices:[],statusLoad:true,statusMensaje:true,mensaje:"INCIANDO DESPLIEGUE DE SERVICIOS",open:false,tokenConfirm:''});

         response.json().then(data => {

         
            this.setState({allServices:data});
            
            this.getServices();
          
        });

        if (response.status === 302) {
          
          this.setState({processedServices:[],statusLoad:true,statusMensaje:true,mensaje:"LOS SERVICIOS ESTAN SIENDO LANZADOS, ESPERE HASTA TERMINAR EL PROCESO"});
          response.json().then(data => {

           
            this.setState({allServices:data});
            
            this.getServices();
          
        });

         
       }

        
      }
      
      if (response.status === 208) {
        
        this.setState({statusMensaje:true,mensaje:"INTENTE NUEVAMENTE",open:false,tokenConfirm:''});
       
     }
    });
    
    
  }

  handleClose(){
    this.setState({open:false});
    
  }

  onChange(e) {



    this.setState({ nombreVendedor: e.target.value.toUpperCase() })


  }

  onChange2(e) {



    // this.setState({ [e.target.name]: e.target.value })


     const onlyNums = e.target.value.replace(/[^0-9]/g, '');
   if (onlyNums.length < 15) {
       this.setState({ [e.target.name]: onlyNums });
   } else if (onlyNums.length > 5) {
       /*const number = onlyNums.replace(
           /(\d{3})(\d{3})(\d{4})/,
           '($1) $2-$3'
       );*/
       this.setState({ cedula: onlyNums });
   }

 }

 eliminarVendedor(s,id, nombreProducto){

  this.setState({nombreVendedor:nombreProducto,idProductoGlobal:id});

 }

 onChange3(e,f){


  this.getUsuarios(f);
  
 }

 eliminarproducto(){



  if(this.state.idProductoGlobal!=0){

    this.setState({statusLoad:true});
    fetch(this.state.serverUrl + "/api/productosfitomedics/?id="+this.state.idProductoGlobal, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //Authorization: "Bearer " + this.state.token,
        
      },
     
  
      }) .then(response => {
         
          if (response.status === 200) {
              
              response.json().then(data => {
                this.getUsuarios(1); 
                this.setState({statusLoad:false,idProductoGlobal:0,nombreVendedor:""});
            
              });
          }
        
      });

  }else{

    alert("seleccione un producto primero");
  }


 }

 actualizarproducto(){


  if(this.state.idProductoGlobal!=0){
        this.setState({statusLoad:true});

        let data = {nombre_producto: this.state.nombreVendedor,id:this.state.idProductoGlobal};
        fetch(this.state.serverUrl + "/api/productosfitomedics/", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + this.state.token,
            
          },
          body: JSON.stringify(data) 

      }) .then(response => {
          if (response.status === 200) {

            this.getUsuarios(1); 
            this.setState({username:"",nombreVendedor:"",idProductoGlobal:0})
            this.setState({statusLoad:false});
          }

          if (response.status === 400) {

            alert("EL PRODUCTO YA EXISTE");
            this.setState({statusLoad:false});
          }
        
      });

    }else{

      alert("seleccione un producto primero");
    }

 }



  render() {
    return (
      <div>


      
        <Formulario
          createproducto={this.createproducto}
          handleClickOpen={this.handleClickOpen}
          nombreVendedor={this.state.nombreVendedor}
          cedula={this.state.cedula}
          processedServices={this.state.processedServices}
          onChange={this.onChange}
          onChange2={this.onChange2}
          onChange3={this.onChange3}
          eliminarVendedor={this.eliminarVendedor}
          
          totalPages1={this.state.totalPagesUsuarios}

          allProductos={this.state.listadoUsuarios}
          actualizarproducto={this.actualizarproducto}
          eliminarproducto={this.eliminarproducto}
      
        ></Formulario>
        <br /> <br />

        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> 

        <center>
          <h2>{this.state.statusMensaje ? this.state.mensaje : ""}</h2>
        </center>


      </div>
    );
  }
}

export default Productos;

import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Container from '@material-ui/core/Container';
import Divider from "@material-ui/core/Divider";
import Pagination from "@material-ui/lab/Pagination";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import TableRow from '@material-ui/core/TableRow';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  TimePicker
} from '@material-ui/pickers';


import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
}));
export default function Formulario(props) {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
  
      <Grid >
        <Grid item xs={12} >
          <center>
            <h2>CREAR PROSPECTO</h2>
          </center>


          <Paper className={classes.paper}>
            <div className={classes.root}>
              <div>
              
                  <FormControl>
                    <TextField
                      id="outlined-full-width"
                      label=""
                      required
                      style={{ margin: 8 }}
                      placeholder="NOMBRE TIENDA"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={props.nombretienda}
                      onChange={props.onChangeCliente}
                      name="nombretienda"
                    />
                    <br />

                    <TextField
                      id="outlined-full-width"
                      label=""
                      required
                      style={{ margin: 8 }}
                      placeholder="NOMBRE PROPIETARIO"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={props.nombrepropietario}
                      onChange={props.onChangeCliente}
                      name="nombrepropietario"
                    />
                    <br />

                    <TextField
                      id="outlined-full-width"
                      label=""
                      required
                      style={{ margin: 8 }}
                      placeholder="NOMBRE QUIEN ATIENDE"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={props.nombrequienatienda}
                      onChange={props.onChangeCliente}
                      name="nombrequienatienda"
                    />
                    <br />

                    <TextField
                      id="outlined-full-width"
                      label=""
                      required
                      style={{ margin: 8 }}
                      placeholder="DIRECCION"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={props.direccion}
                      onChange={props.onChangeCliente}
                      name="direccion"
                    />
                    <br />

                    <TextField
                      id="outlined-full-width"
                      label=""
                      required
                      style={{ margin: 8 }}
                      placeholder="TEL FIJO TIENDA"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={props.telfijotieda}
                      onChange={props.onChangeCliente}
                      name="telfijotieda"
                    />
                    <br />

                    <TextField
                      id="outlined-full-width"
                      label=""
                      required
                      style={{ margin: 8 }}
                      placeholder="TEL CELULAR ENCARGADO"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={props.telcelularencargado}
                      onChange={props.onChangeCliente}
                      name="telcelularencargado"
                    />
                    <br />

                    <Divider />
                    <br />
            
                 


                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={props.uploadPedido}
                    >
                      CREAR PROSPECTO
                    </Button>
               
                    <h2>{props.mensaje}</h2>


                    {props.listadoPedidos!==undefined? (

<TableContainer component={Paper}>
<Table className={classes.table} aria-label="simple table">
<TableHead>
<TableRow>
<TableCell>NOMBRE TIENDA</TableCell>
<TableCell align="right">NOMBRE PROPIETARIO</TableCell>
<TableCell align="right">NOMBRE QUIEN ATIENDE</TableCell>
<TableCell align="right">DIRECCION</TableCell>
<TableCell align="right">TEL FIJO TIENDA</TableCell>
<TableCell align="right">TEL CELULAR ENCARGADO</TableCell>
<TableCell align="right">LATITUD</TableCell>
<TableCell align="right">LONGITUD</TableCell>


</TableRow>
</TableHead>

<TableBody>
{props.listadoPedidos.map((row) => (
  row.cargado_soporte?"":<TableRow key={row.id} hover role="checkbox" onClick={(e) =>
    props.selectedCLiente(e, row.id,row.nombre,row.nit, row.locked_gps)}
  >

<TableCell align="right">{row.nombre_tienda}</TableCell>
<TableCell align="right">{row.nombre_propieatario}</TableCell>
<TableCell align="right">{row.nombre_quien_atiende}</TableCell>

<TableCell align="right">{row.direccion}</TableCell>
<TableCell align="right">{row.tel_fijo_tienda}</TableCell>
<TableCell align="right">{row.tel_celular_encargado}</TableCell>

<TableCell align="right">{row.latitude}</TableCell>
<TableCell align="right">{row.longitude}</TableCell>

</TableRow>
))}
</TableBody>

</Table>
</TableContainer>

):("void")}

<br></br>
<Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={props.updateGPS}
                    >
                      ACTUALIZAR GPS
                    </Button>

                  </FormControl>
              
              </div>
            </div>
          </Paper>
        </Grid>

   
      </Grid>
   
  );
}





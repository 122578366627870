import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';


import Pagination from "@material-ui/lab/Pagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import clsx from "clsx";

import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Formulario(props) {
  const classes = useStyles();

  return (
  
      <Grid >
        <Grid item xs={12} >
          <center>
            <h2>DESBLOQUEO DE VISITAS</h2>
          </center>
          <Paper className={classes.paper}>
            <div className={classes.root}>
              <div>
                
                  <FormControl>
      


                    {props.allProductos!==undefined? (

<TableContainer component={Paper}>
<Table className={classes.table} aria-label="simple table">
<TableHead>
<TableRow>
<TableCell>ID</TableCell>
<TableCell>ASESOR</TableCell>
<TableCell>CEDULA</TableCell>
<TableCell>TIENDA/PROSPECTO</TableCell>
<TableCell>FECHA_VISITA_PROGRAMADA</TableCell>
<TableCell>FECHA_VISITA_APERTURA</TableCell>
<TableCell >ACCION</TableCell>
</TableRow>
</TableHead>

<TableBody>
{props.allProductos.map((row) => (
<TableRow key={row.id}>
<TableCell >{row.id}</TableCell>
<TableCell >{row.nombre_vendedor}</TableCell>
<TableCell >{row.cedula_vendedor}</TableCell>
<TableCell >{row.nombre_tienda}</TableCell>
<TableCell >{row.fecha}</TableCell>
<TableCell >{row.fecha_visita_apertura}</TableCell>
<TableCell >
          <button
            onClick={(e) =>
              props.desbloquearVendedor(e, row.id, row.fk_id_visitasasesorestoclientestienda)
            }
          >
            Desbloquear
          </button>
        </TableCell>
</TableRow>
))}
</TableBody>

</Table>
</TableContainer>

):("void")}



<Grid
container
spacing={0}
direction="column"
alignItems="center"
justify="center"
><br></br>
<center>
<Pagination
count={parseInt(props.totalPages1)}
color="primary"
onChange={props.onChange3}
/>
<br/>


</center>
</Grid>

                  </FormControl>
           
              </div>
            </div>
          </Paper>
        </Grid>

   
      </Grid>
   
  );
}
